import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PrimaryButton } from '../Button/Button';

import css from './TransactionPanel.module.css';

const CancelActionButtonMaybe = ({ inProgress, disabled, onCancel, error, showButtons }) => {
  return showButtons ? (
    <>
      {error && (
        <p className={css.actionError}>
          <FormattedMessage id="TransactionPanel.cancelFailed" />
        </p>
      )}
      <div className={css.cancelButtonWrap}>
        <PrimaryButton
          className={css.cancelButton}
          inProgress={inProgress}
          disabled={disabled}
          onClick={onCancel}
        >
          <FormattedMessage id="TransactionPanel.cancelButton" />
        </PrimaryButton>
      </div>
    </>
  ) : null;
};

export default CancelActionButtonMaybe;
