import React from 'react';
import config from '../../util/hooks/useMarketplaceConfigByLocation';
import { Form, FieldSelect, IconSearch, SecondaryButton } from '../../components';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import css from './HeroSearchBarForm.module.css';
import markIcon from '../../assets/icons/mark-icon.png';
import { findOptionsForSelectFilter } from '../../util/search';
import { FormattedMessage } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

const HeroSearchBarForm = props => {
  const handleSubmitSearch = values => {
    const { history } = props;
    const { location, serviceCategory } = values;
    const searchParams = {
      pub_locations: location,
      pub_serviceCategory: serviceCategory,
    };

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  };

  return (
    <FinalForm
      {...props}
      onSubmit={handleSubmitSearch}
      render={fieldRenderProps => {
        const { className, handleSubmit, intl, animation } = fieldRenderProps;
        const classes = classNames(className || css.root, { [css.hasAnimation]: animation });
        const locations = config.maps.search.defaults;
        const filterConfig = config.custom.filters;
        const serviceCategories = findOptionsForSelectFilter('serviceCategory', filterConfig);
        const locationPlaceholder = intl.formatMessage({
          id: 'HeroSearchBarForm.locationPlaceholder',
        });
        const servicePlaceholder = intl.formatMessage({
          id: 'HeroSearchBarForm.servicePlaceholder',
        });
        const labelSuffixes = intl.formatMessage({
          id: 'HeroSearchBarForm.labelSuffixes',
        });
        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div className={css.inputFields}>
              <div className={css.field}>
                <img src={markIcon} className={css.markIcon} />
                <FieldSelect className={css.select} id="location" name="location">
                  <option value="" disabled>
                    {locationPlaceholder}
                  </option>
                  {locations.map(location => (
                    <option value={location.key} disabled={location.hidden} key={location.key}>
                      {location.hidden ? location.label : location.label}
                    </option>
                  ))}
                </FieldSelect>
              </div>
              <div className={css.field}>
                <IconSearch className={css.markIcon} />
                <FieldSelect className={css.select} id="serviceCategory" name="serviceCategory">
                  <option value="" disabled>
                    {servicePlaceholder}
                  </option>
                  {serviceCategories.map(service => (
                    <option value={service.key} key={service.key} disabled={service.hidden}>
                      {service.hidden ? service.label + ' ' + labelSuffixes : service.label}
                    </option>
                  ))}
                </FieldSelect>
              </div>
            </div>
            <SecondaryButton className={css.submitButton}>
              <FormattedMessage id="HeroSearhBarForm.submitButton" />
            </SecondaryButton>
          </Form>
        );
      }}
    />
  );
};

export default HeroSearchBarForm;
