import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { mapLimit } from 'async';

import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import {
  TRANSITIONS,
  TRANSITION_ACCEPT,
  TRANSITION_EXPIRE_CANCELLATION,
} from '../../util/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { denormalisedResponseEntities } from '../../util/data';
import * as log from '../../util/log';

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

const sortedAcceptedTransactions = txs =>
  sortBy(txs, tx => {
    return tx.booking && tx.booking.attributes ? tx.booking.attributes.start : null;
  });

const generateArrayFromValue = value => Array.from({ length: value }, (_, i) => i + 1);

const LIMIT_PER_QUERY = 5;
const MAX_PAGINATION_LIMIT_SUPPORTED = 10000;
const INBOX_PAGE_SIZE = 10;

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/InboxPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/InboxPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/InboxPage/FETCH_ORDERS_OR_SALES_ERROR';

export const FETCH_ACCEPTED_ORDERS_OR_SALES_SUCCESS =
  'app/InboxPage/FETCH_ACCEPTED_ORDERS_OR_SALES_SUCCESS';

// ================ Reducer ================ //

const entityRefs = entities => {
  return entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));
};

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };
    case FETCH_ACCEPTED_ORDERS_OR_SALES_SUCCESS: {
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(payload.data.data),
        pagination: payload.data.meta,
      };
    }
    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

const fetchAcceptedOrdersOrSalesSuccess = response => ({
  type: FETCH_ACCEPTED_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});

// ================ Helpers ================ //

const queryAllAcceptedTransactions = async (
  sdk,
  initialQueryParams,
  limitPerQuery = LIMIT_PER_QUERY
) => {
  try {
    const allAcceptedTransactionsFirstPage = await sdk.transactions.query(initialQueryParams);
    const meta = allAcceptedTransactionsFirstPage?.data?.meta;
    const metaTotalPagesToArray = generateArrayFromValue(meta?.totalPages);
    const queryParamsListFromTotalPages = metaTotalPagesToArray.slice(1).map(item => {
      return { ...initialQueryParams, page: item };
    });

    const getTransactions = async params => {
      return await sdk.transactions.query(params);
    };

    const restAcceptedTransactions = await mapLimit(
      queryParamsListFromTotalPages,
      limitPerQuery,
      getTransactions
    );

    return [allAcceptedTransactionsFirstPage].concat(restAcceptedTransactions)[0];
  } catch (e) {
    log.error(e, 'query-all-accepted-transactions-failed');
    throw e;
  }
};

const calculatePagination = (page, response) => {
  try {
    const totalItems = response.length;

    const responseByPage = response.slice((page - 1) * INBOX_PAGE_SIZE, INBOX_PAGE_SIZE * page);

    const totalPages =
      Math.floor(totalItems / INBOX_PAGE_SIZE) + (totalItems % INBOX_PAGE_SIZE > 0 ? 1 : 0);

    const paginationLimit =
      totalPages - page > MAX_PAGINATION_LIMIT_SUPPORTED
        ? page + MAX_PAGINATION_LIMIT_SUPPORTED
        : totalPages;

    const meta = {
      page,
      paginationLimit,
      totalPages,
      perPage: INBOX_PAGE_SIZE,
      totalItems,
    };

    return { meta, responseByPage };
  } catch (e) {
    log.error(e, 'calculate-pagination-accepted-transactions-failed');
    throw e;
  }
};

// ================ Thunks ================ //

export const loadData = (params, search) => async (dispatch, getState, sdk) => {
  try {
    const { tab } = params;

    const onlyFilterValues = {
      orders: 'order',
      sales: 'sale',
      'accepted-orders': 'order',
      'accepted-sales': 'sale',
    };

    const isAcceptedOrders = tab === 'accepted-orders';
    const isAcceptedSales = tab === 'accepted-sales';
    const isAcceptedFilter = isAcceptedOrders || isAcceptedSales;
    const lastTransitions = isAcceptedFilter
      ? [TRANSITION_ACCEPT, TRANSITION_EXPIRE_CANCELLATION]
      : TRANSITIONS;

    const onlyFilter = onlyFilterValues[tab];
    if (!onlyFilter) {
      return Promise.reject(new Error(`Invalid tab for InboxPage: ${tab}`));
    }

    dispatch(fetchOrdersOrSalesRequest());

    const { page = 1 } = parse(search);

    const apiQueryParams = {
      only: onlyFilter,
      lastTransitions,
      ...(isAcceptedFilter ? {} : { page, perPage: INBOX_PAGE_SIZE }),
      include: [
        'provider',
        'provider.profileImage',
        'customer',
        'customer.profileImage',
        'booking',
        'listing',
      ],
      'fields.transaction': [
        'lastTransition',
        'lastTransitionedAt',
        'transitions',
        'payinTotal',
        'payoutTotal',
      ],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };

    let response;

    if (isAcceptedFilter) {
      const allAcceptedTransactions = await queryAllAcceptedTransactions(sdk, apiQueryParams);

      const relationships = allAcceptedTransactions?.data?.data.reduce((res, curr) => {
        return { ...res, [curr.id.uuid]: curr.relationships };
      }, {});

      const denormalisedAllAcceptedTransactions = denormalisedResponseEntities(
        allAcceptedTransactions
      );

      const sortedTransactions = sortedAcceptedTransactions(denormalisedAllAcceptedTransactions);

      const { meta, responseByPage } = calculatePagination(page, sortedTransactions);

      const responseByPageWithRelationships = responseByPage.map(res => ({
        ...res,
        relationships: relationships[res.id.uuid],
      }));

      response = {
        data: {
          meta,
          data: responseByPageWithRelationships,
          included: allAcceptedTransactions.data.included,
        },
      };

      dispatch(fetchAcceptedOrdersOrSalesSuccess(response));
    } else {
      response = await sdk.transactions.query(apiQueryParams);
      dispatch(fetchOrdersOrSalesSuccess(response));
    }
    dispatch(addMarketplaceEntities(response));

    return response;
  } catch (e) {
    dispatch(fetchOrdersOrSalesError(storableError(e)));
    throw e;
  }
};
