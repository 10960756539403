import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingPricingForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../util/hooks/useMarketplaceConfigByLocation';
import minBy from 'lodash/minBy';

import css from './EditListingPricingPanel.module.css';
import { useIntl } from 'react-intl/dist/react-intl';

const { Money } = sdkTypes;

const EditListingPricingPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    panelUpdated,
    updateInProgress,
    errors,
    textNextButton,
    textPreviousButton,
    isNewListingFlow,
    onGoBack,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { price, publicData } = currentListing.attributes;
  const intl = useIntl();

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPricingPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditListingPricingPanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingPricingPanel.createListingTitle" />
  );

  const services = (publicData && publicData.services) || [];
  // In the future when we have list of service, please provide serviceLabel for serviceName
  const initPrices = services.map(service => ({
    ...service,
    sessionLengthText: intl.formatMessage({ id: `SessionLength.label.${service.sessionLength}` }),
    price: service.price ? new Money(service.price?.amount, service.price?.currency) : null,
  }));

  const inLiveSite = config.currentCountryCode === undefined

  const priceCurrencyValid = inLiveSite ? inLiveSite : price instanceof Money ? price.currency === config.currency : true;
  
  const initialValues = useMemo(()=>({ price: initPrices, currency : price?.currency }),[currentListing.id])

  const form = priceCurrencyValid ? (
    <EditListingPricingForm
      className={css.form}
      initialValues={initialValues}
      onSubmit={values => {
        const minPriceService = minBy(values.price, pr => pr?.price?.amount);

        const updatedValues = {
          publicData: {
            services: values.price.map(pr => ({
              ...pr,
              service: pr.service,
              sessionLength: pr.sessionLength,
              price: {
                amount: pr.price?.amount,
                currency: pr.price?.currency,
              },
            })),
          },
          price: minPriceService?.price,
        };

        onSubmit(updatedValues);
      }}
      onChange={onChange}
      disabled={disabled}
      ready={ready}
      updated={panelUpdated}
      updateInProgress={updateInProgress}
      fetchErrors={errors}
      textNextButton={textNextButton}
      textPreviousButton={textPreviousButton}
      isNewListingFlow={isNewListingFlow}
      onGoBack={onGoBack}
      inLiveSite={inLiveSite}
    />
  ) : (
    <div className={css.priceCurrencyInvalid}>
      <FormattedMessage id="EditListingPricingPanel.listingPriceCurrencyInvalid" />
    </div>
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      {form}
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingPricingPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPricingPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  textNextButton: string.isRequired,
  textPreviousButton: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPricingPanel;
