import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Logo.module.css';
import logoImg from './logo_horizontal_black.png';

const Logo = props => {
  const { className, format, ...rest } = props;
  const classes = classNames(css.root, className);

  // If you want to use image instead of svg as a logo you can use the following code.
  // Also, remember to import the image as LogoImage here.
  // <img className={className} src={LogoImage} alt={config.siteTitle} {...rest} />

  return <img src={logoImg} alt="hubista" className={classes} />;
};

const { oneOf, string } = PropTypes;

Logo.defaultProps = {
  className: null,
  format: 'desktop',
};

Logo.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile']),
};

export default Logo;
