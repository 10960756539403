import useLanguageByLocation from './util/hooks/useLanguageByLocation';
import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// THERE ARE MULTIPLE LOCATION FOR 3 COUNTRIES (ENG,SWEDEN,FINLAND).PLEASE CONFIG TO ALL

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
const defaultLocations = [
  {
    id: 'default-stockholm',
    key: 'stockholm',
    label: 'Stockholm',
    predictionPlace: {
      address: 'Stockholm, Sweden',
      origin: new LatLng(59.33333, 18.16667),
      bounds: new LatLngBounds(
        new LatLng(60.3211392691356, 19.6861696939757),
        new LatLng(58.6852181011858, 17.2443690224356)
      ),
    },
  },
  {
    id: 'default-gothenburg',
    key: 'gothenburg',
    label: 'Gothenburg',
    predictionPlace: {
      address: 'Gothenburg, Västra Götaland, Sweden',
      origin: new LatLng(57.70694, 11.96667),
      bounds: new LatLngBounds(new LatLng(57.787798, 12.088997), new LatLng(57.666494, 11.788165)),
    },
  },
  {
    id: 'default-malmo',
    key: 'malmo',
    label: 'Malmö',
    predictionPlace: {
      address: 'Malmö, Skåne, Sweden',
      origin: new LatLng(55.60556, 13),
      bounds: new LatLngBounds(new LatLng(55.639033, 13.152245), new LatLng(55.547049, 12.944725)),
    },
  },
  {
    id: 'default-uppsala',
    key: 'uppsala',
    label: 'Uppsala',
    predictionPlace: {
      address: 'Uppsala, Sweden',
      origin: new LatLng(59.85, 17.65),
      bounds: new LatLngBounds(
        new LatLng(60.7161988, 18.9638516674287),
        new LatLng(59.3824380461418, 16.6919770128381)
      ),
    },
  },
  {
    id: 'default-linkoping',
    key: 'linkoping',
    label: 'Linköping',
    predictionPlace: {
      address: 'Linköping, Östergötland, Sweden',
      origin: new LatLng(58.40833, 15.625),
      bounds: new LatLngBounds(new LatLng(58.51535, 16.075541), new LatLng(58.178841, 15.43475)),
    },
  },
  {
    id: 'default-orebro',
    key: 'orebro',
    label: 'Örebro',
    predictionPlace: {
      address: 'Örebro, Sweden',
      origin: new LatLng(59.26667, 15.21667),
      bounds: new LatLngBounds(
        new LatLng(60.1104419906409, 15.8006089806483),
        new LatLng(58.6465540199967, 14.2846750001954)
      ),
    },
  },
  {
    id: 'default-vasteras',
    key: 'vasteras',
    label: 'Västerås',
    predictionPlace: {
      address: 'Västerås, Västmanland, Sweden',
      origin: new LatLng(59.61667, 16.53333),
      bounds: new LatLngBounds(new LatLng(59.789516, 16.965567), new LatLng(59.459675, 16.235052)),
    },
  },
  {
    id: 'gavle',
    key: 'gavle',
    label: 'Gävle',
    predictionPlace: {
      address: 'Gävle, Gävleborg, Sweden',
      origin: new LatLng(60.6878236, 17.1194417),
      bounds: new LatLngBounds(new LatLng(59.789516, 16.965567), new LatLng(59.459675, 16.235052)),
    },
  },
  {
    id: 'default-helsinki',
    key: 'helsinki',
    label: 'Helsinki',
    predictionPlace: {
      address: 'Helsinki',
      origin: new LatLng(59.33333, 18.16667),
      bounds: new LatLngBounds(
        new LatLng(60.3211392691356, 19.6861696939757),
        new LatLng(58.6852181011858, 17.2443690224356)
      ),
    },
  },
  {
    id: 'default-espoo',
    key: 'espoo',
    label: 'espoo',
    predictionPlace: {
      address: 'Espoo',
      origin: new LatLng(57.70694, 11.96667),
      bounds: new LatLngBounds(new LatLng(57.787798, 12.088997), new LatLng(57.666494, 11.788165)),
    },
  },
  {
    id: 'default-tampere',
    key: 'tampere',
    label: 'Tampere',
    predictionPlace: {
      address: 'Tampere',
      origin: new LatLng(55.60556, 13),
      bounds: new LatLngBounds(new LatLng(55.639033, 13.152245), new LatLng(55.547049, 12.944725)),
    },
  },
  {
    id: 'default-vantaa',
    key: 'vantaa',
    label: 'Vantaa',
    predictionPlace: {
      address: 'Vantaa',
      origin: new LatLng(59.85, 17.65),
      bounds: new LatLngBounds(
        new LatLng(60.7161988, 18.9638516674287),
        new LatLng(59.3824380461418, 16.6919770128381)
      ),
    },
  },
  {
    id: 'default-oulu',
    key: 'oulu',
    label: 'Oulu ',
    predictionPlace: {
      address: 'Oulu',
      origin: new LatLng(58.40833, 15.625),
      bounds: new LatLngBounds(new LatLng(58.51535, 16.075541), new LatLng(58.178841, 15.43475)),
    },
  },
  {
    id: 'default-turku',
    key: 'turku',
    label: 'Turku',
    predictionPlace: {
      address: 'Turku',
      origin: new LatLng(59.26667, 15.21667),
      bounds: new LatLngBounds(
        new LatLng(60.1104419906409, 15.8006089806483),
        new LatLng(58.6465540199967, 14.2846750001954)
      ),
    },
  },
  {
    id: 'default-jyväskylä',
    key: 'jyväskylä',
    label: 'Jyväskylä',
    predictionPlace: {
      address: 'Jyväskylä',
      origin: new LatLng(59.61667, 16.53333),
      bounds: new LatLngBounds(new LatLng(59.789516, 16.965567), new LatLng(59.459675, 16.235052)),
    },
  },
  {
    id: 'default-kuopio',
    key: 'kuopio',
    label: 'Kuopio',
    predictionPlace: {
      address: 'Kuopio',
      origin: new LatLng(60.6878236, 17.1194417),
      bounds: new LatLngBounds(new LatLng(59.789516, 16.965567), new LatLng(59.459675, 16.235052)),
    },
  },
];

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
const defaultLocationsInFinland = [
  {
    id: 'default-helsinki',
    key: 'helsinki',
    label: 'Helsinki',
    predictionPlace: {
      address: 'Helsinki',
      origin: new LatLng(59.33333, 18.16667),
      bounds: new LatLngBounds(
        new LatLng(60.3211392691356, 19.6861696939757),
        new LatLng(58.6852181011858, 17.2443690224356)
      ),
    },
  },
  {
    id: 'default-espoo',
    key: 'espoo',
    label: 'Espoo',
    predictionPlace: {
      address: 'Espoo',
      origin: new LatLng(57.70694, 11.96667),
      bounds: new LatLngBounds(new LatLng(57.787798, 12.088997), new LatLng(57.666494, 11.788165)),
    },
  },
  {
    id: 'default-tampere',
    key: 'tampere',
    label: 'Tampere',
    predictionPlace: {
      address: 'Tampere',
      origin: new LatLng(55.60556, 13),
      bounds: new LatLngBounds(new LatLng(55.639033, 13.152245), new LatLng(55.547049, 12.944725)),
    },
  },
  {
    id: 'default-vantaa',
    key: 'vantaa',
    label: 'Vantaa',
    predictionPlace: {
      address: 'Vantaa',
      origin: new LatLng(59.85, 17.65),
      bounds: new LatLngBounds(
        new LatLng(60.7161988, 18.9638516674287),
        new LatLng(59.3824380461418, 16.6919770128381)
      ),
    },
  },
  {
    id: 'default-turku',
    key: 'turku',
    label: 'Turku',
    predictionPlace: {
      address: 'Turku',
      origin: new LatLng(59.26667, 15.21667),
      bounds: new LatLngBounds(
        new LatLng(60.1104419906409, 15.8006089806483),
        new LatLng(58.6465540199967, 14.2846750001954)
      ),
    },
  },
  {
    id: 'default-oulu',
    key: 'oulu',
    label: 'Oulu (tulossa pian)',
    hidden: true,
    predictionPlace: {
      address: 'Oulu',
      origin: new LatLng(58.40833, 15.625),
      bounds: new LatLngBounds(new LatLng(58.51535, 16.075541), new LatLng(58.178841, 15.43475)),
    },
  },
  {
    id: 'default-jyväskylä',
    key: 'jyväskylä',
    label: 'Jyväskylä (tulossa pian)',
    hidden: true,
    predictionPlace: {
      address: 'Jyväskylä',
      origin: new LatLng(59.61667, 16.53333),
      bounds: new LatLngBounds(new LatLng(59.789516, 16.965567), new LatLng(59.459675, 16.235052)),
    },
  },
  {
    id: 'default-kuopio',
    key: 'kuopio',
    label: 'Kuopio (tulossa pian)',
    hidden: true,
    predictionPlace: {
      address: 'Kuopio',
      origin: new LatLng(60.6878236, 17.1194417),
      bounds: new LatLngBounds(new LatLng(59.789516, 16.965567), new LatLng(59.459675, 16.235052)),
    },
  },
];

const defaultLocationsInSweden = [
  {
    id: 'default-stockholm',
    key: 'stockholm',
    label: 'Stockholm',
    predictionPlace: {
      address: 'Stockholm, Sweden',
      origin: new LatLng(59.33333, 18.16667),
      bounds: new LatLngBounds(
        new LatLng(60.3211392691356, 19.6861696939757),
        new LatLng(58.6852181011858, 17.2443690224356)
      ),
    },
  },
  {
    id: 'default-gothenburg',
    key: 'gothenburg',
    label: 'Göteborg',
    predictionPlace: {
      address: 'Gothenburg, Västra Götaland, Sweden',
      origin: new LatLng(57.70694, 11.96667),
      bounds: new LatLngBounds(new LatLng(57.787798, 12.088997), new LatLng(57.666494, 11.788165)),
    },
  },
  {
    id: 'default-malmo',
    key: 'malmo',
    label: 'Malmö',
    predictionPlace: {
      address: 'Malmö, Skåne, Sweden',
      origin: new LatLng(55.60556, 13),
      bounds: new LatLngBounds(new LatLng(55.639033, 13.152245), new LatLng(55.547049, 12.944725)),
    },
  },
  {
    id: 'default-uppsala',
    key: 'uppsala',
    label: 'Uppsala (kommer snart)',
    hidden: true,
    predictionPlace: {
      address: 'Uppsala, Sweden',
      origin: new LatLng(59.85, 17.65),
      bounds: new LatLngBounds(
        new LatLng(60.7161988, 18.9638516674287),
        new LatLng(59.3824380461418, 16.6919770128381)
      ),
    },
  },
  {
    id: 'default-linkoping',
    key: 'linkoping',
    label: 'Linköping (kommer snart)',
    hidden: true,
    predictionPlace: {
      address: 'Linköping, Östergötland, Sweden',
      origin: new LatLng(58.40833, 15.625),
      bounds: new LatLngBounds(new LatLng(58.51535, 16.075541), new LatLng(58.178841, 15.43475)),
    },
  },
  {
    id: 'default-orebro',
    key: 'orebro',
    label: 'Örebro (kommer snart)',
    hidden: true,
    predictionPlace: {
      address: 'Örebro, Sweden',
      origin: new LatLng(59.26667, 15.21667),
      bounds: new LatLngBounds(
        new LatLng(60.1104419906409, 15.8006089806483),
        new LatLng(58.6465540199967, 14.2846750001954)
      ),
    },
  },
  {
    id: 'default-vasteras',
    key: 'vasteras',
    label: 'Västerås (kommer snart)',
    hidden: true,
    predictionPlace: {
      address: 'Västerås, Västmanland, Sweden',
      origin: new LatLng(59.61667, 16.53333),
      bounds: new LatLngBounds(new LatLng(59.789516, 16.965567), new LatLng(59.459675, 16.235052)),
    },
  },
  {
    id: 'gavle',
    key: 'gavle',
    label: 'Gävle (kommer snart)',
    hidden: true,
    predictionPlace: {
      address: 'Gävle, Gävleborg, Sweden',
      origin: new LatLng(60.6878236, 17.1194417),
      bounds: new LatLngBounds(new LatLng(59.789516, 16.965567), new LatLng(59.459675, 16.235052)),
    },
  },
];

const currentCountryCode = useLanguageByLocation().countryCode;

const exportLocations =
  currentCountryCode === 'SE'
    ? defaultLocationsInSweden
    : currentCountryCode === 'FI'
    ? defaultLocationsInFinland
    : defaultLocations;

export default exportLocations;
