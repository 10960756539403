const initialState = {
  countryCode: undefined,
};

// Actions

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { type } = action;
  switch (type) {
    default:
      return state;
  }
}

// Action types
