import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, Button, FieldSelect } from '../../components';

import css from './EditListingPoliciesForm.module.css';
import config from '../../util/hooks/useMarketplaceConfigByLocation';
import { required } from '../../util/validators';

export const EditListingPoliciesFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        textNextButton,
        textPreviousButton,
        isNewListingFlow,
        updated,
        updateInProgress,
        fetchErrors,
        onGoBack,
      } = formRenderProps;

      const bookingOptionLabel = intl.formatMessage({
        id: 'EditListingPoliciesForm.bookingOptionLabel',
      });
      const bookingOptionPlaceholder = intl.formatMessage({
        id: 'EditListingPoliciesForm.bookingOptionPlaceholder',
      });
      const bookingOptionRequiredMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.bookingOptionRequired',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <FieldSelect
            id="bookingOption"
            className={css.field}
            label={bookingOptionLabel}
            name="bookingOption"
            validate={required(bookingOptionRequiredMessage)}
          >
            <option value="" disabled>
              {bookingOptionPlaceholder}
            </option>
            {config.custom.bookingOptions.map(option => (
              <option value={option.key} key={option.key}>
                {intl.formatMessage({ id: `BookingOptions.label.${option.key}` })}
              </option>
            ))}
          </FieldSelect>

          <div className={classNames({ [css.wrapperButton]: isNewListingFlow })}>
            {isNewListingFlow && (
              <Button
                className={css.submitButton}
                type="button"
                ready={submitReady}
                onClick={onGoBack}
              >
                {textPreviousButton}
              </Button>
            )}
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {textNextButton}
            </Button>
          </div>
        </Form>
      );
    }}
  />
);

EditListingPoliciesFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingPoliciesFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  textNextButton: string.isRequired,
  textPreviousButton: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPoliciesFormComponent);
