import React, { useState } from 'react';
import { arrayOf, func, number, string, object } from 'prop-types';
import classNames from 'classnames';
import config from '../../util/hooks/useMarketplaceConfigByLocation';

import css from './ServiceCategoryAutocompleteInput.module.css';
import { findOptionsForSelectFilter } from '../../util/search';
import IconHourGlass from './IconHourGlass';

// A list of default predictions that can be shown when the user
// focuses on the autocomplete input without typing a search. This can
// be used to reduce typing and Geocoding API calls for common
// searches.
const filterConfig = config.custom.filters;

const predictions = findOptionsForSelectFilter('serviceCategory', filterConfig);
const defaultPredictions = predictions.filter(pre => !pre.hidden);

const KEY_CODE_ARROW_UP = 38;
const KEY_CODE_ARROW_DOWN = 40;
const KEY_CODE_ENTER = 13;
const KEY_CODE_TAB = 9;
const KEY_CODE_ESC = 27;
const DIRECTION_UP = 'up';
const DIRECTION_DOWN = 'down';
const TOUCH_TAP_RADIUS = 5; // Movement within 5px from touch start is considered a tap

// Touch devices need to be able to distinguish touches for scrolling and touches to tap
const getTouchCoordinates = nativeEvent => {
  const touch = nativeEvent && nativeEvent.changedTouches ? nativeEvent.changedTouches[0] : null;
  return touch ? { x: touch.screenX, y: touch.screenY } : null;
};

// Renders the autocompletion prediction results in a list
const ServiceCategoryPredictionsList = props => {
  const {
    rootClassName,
    className,
    predictions,
    highlightedIndex,
    onSelectStart,
    onSelectMove,
    onSelectEnd,
  } = props;
  if (predictions.length === 0) {
    return null;
  }

  const item = (prediction, index) => {
    const isHighlighted = index === highlightedIndex;

    return (
      <li
        className={isHighlighted ? css.highlighted : null}
        key={prediction.key}
        onTouchStart={e => {
          onSelectStart(getTouchCoordinates(e.nativeEvent));
        }}
        onMouseDown={e => {
          onSelectStart();
        }}
        onTouchMove={e => {
          onSelectMove(getTouchCoordinates(e.nativeEvent));
        }}
        onTouchEnd={e => {
          onSelectEnd(prediction);
        }}
        onMouseUp={e => {
          onSelectEnd(prediction);
        }}
      >
        {prediction.label}
      </li>
    );
  };

  const classes = classNames(rootClassName || css.predictionsRoot, className);

  return (
    <div className={classes}>
      <ul className={css.predictions}>{predictions.map(item)}</ul>
    </div>
  );
};

ServiceCategoryPredictionsList.defaultProps = {
  rootClassName: null,
  className: null,
  attributionClassName: null,
  highlightedIndex: null,
};

ServiceCategoryPredictionsList.propTypes = {
  rootClassName: string,
  className: string,
  attributionClassName: string,
  predictions: arrayOf(object).isRequired,
  highlightedIndex: number,
  onSelectStart: func.isRequired,
  onSelectMove: func.isRequired,
  onSelectEnd: func.isRequired,
};

// Get the current value with defaults from the given
// LocationAutocompleteInput props.
const currentValue = props => {
  const value = props.input.value || {};
  const { search = '', predictions = [], selectedCategory = null } = value;
  return { search, predictions, selectedCategory };
};

/*
  Location auto completion input component

  This component can work as the `component` prop to Final Form's
  <Field /> component. It takes a custom input value shape, and
  controls the onChange callback that is called with the input value.

  The component works by listening to the underlying input component
  and calling a Geocoder implementation for predictions. When the
  predictions arrive, those are passed to Final Form in the onChange
  callback.

  See the LocationAutocompleteInput.example.js file for a usage
  example within a form.
*/
const ServiceCategoryAutocompleteInputImpl = props => {
  const [inputHasFocus, setInputHasFocus] = useState(false);
  const [highlightedIndex, setHightlightIndex] = useState(-1);
  const [selectionInProgress, setSelectionInProgress] = useState(false);

  const [touchStartedFrom, setTouchStartedFrom] = useState(null);

  const [isSwipe, setIsSwipe] = useState(false);
  const [currentPredictions, setCurrentPredictions] = useState(defaultPredictions);
  let inputObj = null;
  const {
    autoFocus,
    rootClassName,
    className,
    iconClassName,
    inputClassName,
    predictionsClassName,
    predictionsAttributionClassName,
    validClassName,
    placeholder,
    input,
    meta,
    inputRef,
  } = props;
  const { name, onFocus } = input;
  const { search } = currentValue(props);
  const { touched, valid } = meta || {};
  const isValid = valid && touched;

  const handleOnFocus = e => {
    setInputHasFocus(true);
    onFocus(e);
  };

  const rootClass = classNames(rootClassName || css.root, className);
  const iconClass = classNames(iconClassName || css.icon);
  const inputClass = classNames(inputClassName || css.input, { [validClassName]: isValid });
  const predictionsClass = classNames(predictionsClassName);

  // Only render predictions when the input has focus. For
  // development and easier workflow with the browser devtools, you
  // might want to hardcode this to `true`. Otherwise the dropdown
  // list will disappear.
  const renderPredictions = inputHasFocus;
  const handlePredictionsSelectStart = touchCoordinates => {
    setSelectionInProgress(true);
    setTouchStartedFrom(touchCoordinates);
    setIsSwipe(false);
  };
  const handlePredictionsSelectMove = touchCoordinates => {
    setSelectionInProgress(false);
    setIsSwipe(() => {
      const isTouchAction = !!touchStartedFrom;
      const isSwipe = isTouchAction
        ? Math.abs(touchStartedFrom.y - touchCoordinates.y) > TOUCH_TAP_RADIUS
        : false;
      return isSwipe;
    });
  };
  const selectPrediction = prediction => {
    const obj = { search: prediction.label, predictions: [], selectedCategory: prediction };
    props.input.onChange(obj);
  };

  const finalizeSelection = () => {
    setInputHasFocus(false);
    setHightlightIndex(-1);
    props.input.onBlur(currentValue(props));
  };

  const handlePredictionsSelectEnd = prediction => {
    let selectAndFinalize = false;
    if (!isSwipe) {
      selectAndFinalize = true;
    }
    setSelectionInProgress(false);
    setTouchStartedFrom(null);
    setIsSwipe(false);
    if (selectAndFinalize) {
      selectPrediction(prediction);
      finalizeSelection();
    }
  };

  const handleOnBlur = () => {
    if (props.closeOnBlur && !selectionInProgress) {
      finalizeSelection();
    }
  };

  const predict = value => {
    if (!value) return setCurrentPredictions(defaultPredictions);
    const newPredictions = currentPredictions.filter(item => {
      return item && item.label && item.label.toLowerCase().includes(value);
    });
    setCurrentPredictions(newPredictions);
  };

  const onChange = e => {
    const onChange = props.input.onChange;
    const predictions = defaultPredictions;
    const newValue = e.target.value;

    // Clear the current values since the input content is changed
    onChange({
      search: newValue,
      predictions: newValue ? predictions : [],
      selectedCategory: null,
    });

    predict(newValue);

    // Clear highlighted prediction since the input value changed and
    // results will change as well
    setHightlightIndex(-1);
  };
  const selectItemIfNoneSelected = () => {
    const { selectedCategory } = currentValue(props);
    if (!selectedCategory) {
      if (currentPredictions && currentPredictions.length > 0) {
        const index = highlightedIndex !== -1 ? highlightedIndex : 0;
        selectPrediction(currentPredictions[index]);
      }
    }
  };

  const changeHighlight = direction => {
    const predictions = currentPredictions;
    const currentIndex = highlightedIndex;
    let index = currentIndex;

    if (direction === DIRECTION_UP) {
      // Keep the first position if already highlighted
      index = currentIndex === 0 ? 0 : currentIndex - 1;
    } else if (direction === DIRECTION_DOWN) {
      index = currentIndex + 1;
    }

    // Check that the index is within the bounds
    if (index < 0) {
      index = -1;
    } else if (index >= predictions.length) {
      index = predictions.length - 1;
    }
    setHightlightIndex(index);
  };

  // Interpret input key event
  const onKeyDown = e => {
    if (e.keyCode === KEY_CODE_ARROW_UP) {
      // Prevent changing cursor position in input
      e.preventDefault();
      changeHighlight(DIRECTION_UP);
    } else if (e.keyCode === KEY_CODE_ARROW_DOWN) {
      // Prevent changing cursor position in input
      e.preventDefault();
      changeHighlight(DIRECTION_DOWN);
    } else if (e.keyCode === KEY_CODE_ENTER) {
      const { selectedCategory } = currentValue(props);

      if (!selectedCategory) {
        // Prevent form submit, try to select value instead.
        e.preventDefault();
        e.stopPropagation();
        selectItemIfNoneSelected();
        inputObj.blur();
      }
    } else if (e.keyCode === KEY_CODE_TAB) {
      selectItemIfNoneSelected();
      inputObj.blur();
    } else if (e.keyCode === KEY_CODE_ESC && inputObj) {
      inputObj.blur();
    }
  };

  return (
    <div className={rootClass}>
      <div className={iconClass}>
        <IconHourGlass />
      </div>
      <input
        className={inputClass}
        type="search"
        autoComplete="off"
        autoFocus={autoFocus}
        placeholder={placeholder}
        name={name}
        value={search}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        onChange={onChange}
        onKeyDown={onKeyDown}
        ref={node => {
          inputObj = node;
          if (inputRef) {
            inputRef(node);
          }
        }}
        readOnly
      />
      {renderPredictions && (
        <ServiceCategoryPredictionsList
          rootClassName={predictionsClass}
          attributionClassName={predictionsAttributionClassName}
          highlightedIndex={highlightedIndex}
          onSelectStart={handlePredictionsSelectStart}
          onSelectMove={handlePredictionsSelectMove}
          onSelectEnd={handlePredictionsSelectEnd}
          predictions={currentPredictions}
        />
      )}
    </div>
  );
};

export default ServiceCategoryAutocompleteInputImpl;
