/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const filters = [
  {
    id: 'location',
    label: 'Location',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_locations'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'stockholm',
          address: 'Stockholm, Sweden',
          label: 'Stockholm',
        },
        {
          key: 'gothenburg',
          address: 'Gothenburg, Västra Götaland, Sweden',
          label: 'Gothenburg',
        },
        {
          key: 'malmo',
          address: 'Malmö, Skåne, Sweden',
          label: 'Malmö',
        },
        {
          key: 'uppsala',
          address: 'Uppsala, Sweden',
          label: 'Uppsala',
        },
        {
          key: 'linkoping',
          address: 'Linköping, Östergötland, Sweden',
          label: 'Linköping',
        },
        {
          key: 'orebro',
          address: 'Örebro, Sweden',
          label: 'Örebro',
        },
        {
          key: 'vasteras',
          address: 'Västerås, Västmanland, Sweden',
          label: 'Västerås',
        },
        {
          key: 'gavle',
          address: 'Gävle, Sweden',
          label: 'Gävle',
        },
        {
          key: 'helsinki',
          address: 'Helsinki',
          label: 'Helsinki',
        },
        {
          key: 'espoo',
          address: 'Espoo',
          label: 'Espoo',
        },
        {
          key: 'tampere',
          address: 'Tampere',
          label: 'Tampere',
        },
        {
          key: 'vantaa',
          address: 'Vantaa',
          label: 'Vantaa',
        },
        {
          key: 'oulu',
          address: 'Oulu',
          label: 'Oulu',
        },
        {
          key: 'turku',
          address: 'Turku',
          label: 'Turku',
        },
        {
          key: 'jyväskylä',
          address: 'Jyväskylä',
          label: 'Jyväskylä',
        },
        {
          key: 'kuopio',
          address: 'Kuopio',
          label: 'Kuopio',
        },
      ],
    },
  },
  {
    id: 'serviceCategory',
    label: 'Service Category',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_serviceCategory'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'hairdresser', label: 'Hairdresser' },
        { key: 'musician', label: 'Musician' },
        { key: 'musicTeacher', label: 'Music teacher' },
        { key: 'photograph', label: 'Photograph' },
        { key: 'videoMaker', label: 'Video maker' },
        { key: 'chef', label: 'Chef' },
        { key: 'bartender', label: 'Bartender' },
        { key: 'sommelier', label: 'Sommelier' },
        { key: 'personalTrainer', label: 'Personal Trainer' },
        { key: 'masseur', label: 'Masseur' },
        { key: 'chiropractor', label: 'Chiropractor' },
        { key: 'dogGrooming', label: 'Dog grooming' },
        { key: 'dogSitting', label: 'Dog sitting' },
        { key: 'seniorCompanionship', label: 'Senior companionship' },
        { key: 'babySitting', label: 'Baby sitting' },
      ],
    },
  },
  {
    id: 'dates-length',
    label: 'Date & Time',
    type: 'BookingDateRangeLengthFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates', 'minDuration', 'timeSlot'],
    config: {
      // A global time zone to use in availability searches. As listings
      // can be in various time zones, we must decide what time zone we
      // use in search when looking for available listings within a
      // certain time interval.
      //
      // If you have all/most listings in a certain time zone, change this
      // config value to that.
      //
      // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
      searchTimeZone: 'Etc/UTC',

      // Options for the minimum duration of the booking
      options: [
        { key: '0', label: 'Any length' },
        { key: '30', label: '30 minutes', shortLabel: '30m' },
        { key: '45', label: '45 minutes', shortLabel: '45m' },
        { key: '60', label: '1 hour', shortLabel: '1h' },
        { key: '90', label: '1.5 hours', shortLabel: '1.5h' },
        { key: '120', label: '2 hours', shortLabel: '2h' },
      ],
    },
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 1000,
      max: 50000,
      step: 1,
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  {
    id: 'rating',
    label: 'Rating',
    type: 'RatingFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    queryParamNames: ['meta_averageRating'],
    config: {
      options: [{ key: 1 }, { key: 2 }, { key: 3 }, { key: 4 }, { key: 5 }],
    },
  },

  /*Certificate (title)
PRO seller (opt 1)
Educated (opt 2)
Delivered services (title)
50 (opt 1)
100 (opt 2)
150 (opt 3) */

  // {
  //   id: 'certificate',
  //   label: 'Certificate',
  //   type: 'SelectSingleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_certificate'],
  //   config: {
  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for this web app's UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [{ key: 'pro', label: 'PRO seller' }, { key: 'educated', label: 'Educated' }],
  //   },
  // },
  // {
  //   id: 'deliveredServices',
  //   label: 'Delivered services',
  //   type: 'SelectSingleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_deliveredServices'],
  //   config: {
  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for this web app's UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: '30', label: '30' },
  //       { key: '45', label: '45' },
  //       { key: '60', label: '60' },
  //       { key: '90', label: '90' },
  //       { key: '120', label: '120' },
  //     ],
  //   },
  // },
];

export const filtersInFinland = [
  {
    id: 'location',
    label: 'Paikkakunta',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_locations'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'helsinki',
          address: 'Helsinki',
          label: 'Helsinki',
        },
        {
          key: 'espoo',
          address: 'Espoo',
          label: 'Espoo',
        },
        {
          key: 'tampere',
          address: 'Tampere',
          label: 'Tampere',
        },
        {
          key: 'vantaa',
          address: 'Vantaa',
          label: 'Vantaa',
        },
        {
          key: 'turku',
          address: 'Turku',
          label: 'Turku',
        },
        {
          key: 'oulu',
          address: 'Oulu',
          label: 'Oulu',
          hidden: true,
        },
        {
          key: 'jyväskylä',
          address: 'Jyväskylä',
          label: 'Jyväskylä',
          hidden: true,
        },
        {
          key: 'kuopio',
          address: 'Kuopio',
          label: 'Kuopio',
          hidden: true,
        },
      ],
    },
  },
  {
    id: 'serviceCategory',
    label: 'Kategoria',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_serviceCategory'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        // { key: 'musician', label: 'Solisti / Muusikko / DJ' },
        // { key: 'musicTeacher', label: 'Musiikinopettaja' },
        { key: 'photograph', label: 'Valokuvaaja' },
        { key: 'videoMaker', label: 'Videokuvaaja' },
        // { key: 'hairdresser', label: 'Parturi / kampaaja', hidden: true },
        // { key: 'chef', label: 'Kokki', hidden: true },
        // { key: 'masseur', label: 'Hieroja', hidden: true },
        // { key: 'dogGrooming', label: 'Koiran trimmaus', hidden: true },
        // { key: 'dogSitting', label: 'Koiranhoito ja ulkoilutus', hidden: true },
        // { key: 'bartender', label: 'Baarimestari', hidden: true },
        // { key: 'sommelier', label: 'Sommelieeri', hidden: true },
        // { key: 'personalTrainer', label: 'Personal trainer', hidden: true },
        // { key: 'chiropractor', label: 'Kiropraktikko', hidden: true },
        // { key: 'seniorCompanionship', label: 'Vanhusseura', hidden: true },
        // { key: 'babySitting', label: 'Lapsenvahti', hidden: true },
      ],
    },
  },
  {
    id: 'dates-length',
    label: 'Päivämäärä Kellonaika',
    type: 'BookingDateRangeLengthFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates', 'minDuration', 'timeSlot'],
    config: {
      // A global time zone to use in availability searches. As listings
      // can be in various time zones, we must decide what time zone we
      // use in search when looking for available listings within a
      // certain time interval.
      //
      // If you have all/most listings in a certain time zone, change this
      // config value to that.
      //
      // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
      searchTimeZone: 'Etc/UTC',

      // Options for the minimum duration of the booking
      options: [
        { key: '0', label: 'Mikä tahansa pituus' },
        { key: '30', label: '30 minuuttia', shortLabel: '30m' },
        { key: '45', label: '45 minuuttia', shortLabel: '45m' },
        { key: '60', label: '1 tunti', shortLabel: '1h' },
        { key: '90', label: '1.5 tuntia', shortLabel: '1.5h' },
        { key: '120', label: '2 tuntia', shortLabel: '2h' },
      ],
    },
  },
  {
    id: 'price',
    label: 'Hinta',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 5000,
      step: 1,
    },
  },
  {
    id: 'keyword',
    label: 'Hakusana',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  {
    id: 'rating',
    label: 'Käyttäjäarvostelut',
    type: 'RatingFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    queryParamNames: ['meta_averageRating'],
    config: {
      options: [{ key: 1 }, { key: 2 }, { key: 3 }, { key: 4 }, { key: 5 }],
    },
  },
];

export const filtersInSweden = [
  {
    id: 'location',
    label: 'Plats',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_locations'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'stockholm',
          address: 'Stockholm, Sweden',
          label: 'Stockholm',
        },
        {
          key: 'gothenburg',
          address: 'Göteborg, Västra Götaland, Sweden',
          label: 'Göteborg',
        },
        {
          key: 'malmo',
          address: 'Malmö, Skåne, Sweden',
          label: 'Malmö',
        },
        {
          key: 'uppsala',
          address: 'Uppsala, Sweden',
          label: 'Uppsala',
          hidden: true,
        },
        {
          key: 'linkoping',
          address: 'Linköping, Östergötland, Sweden',
          label: 'Linköping',
          hidden: true,
        },
        {
          key: 'orebro',
          address: 'Örebro, Sweden',
          label: 'Örebro',
          hidden: true,
        },
        {
          key: 'vasteras',
          address: 'Västerås, Västmanland, Sweden',
          label: 'Västerås',
          hidden: true,
        },
        {
          key: 'gavle',
          address: 'Gävle, Sweden',
          label: 'Gävle',
          hidden: true,
        },
      ],
    },
  },
  {
    id: 'serviceCategory',
    label: 'Tjänst',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_serviceCategory'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        // { key: 'musician', label: 'Musiker' },
        // { key: 'musicTeacher', label: 'Musiklärare' },
        { key: 'photograph', label: 'Fotograf' },
        { key: 'videoMaker', label: 'Videokreatör' },
        // { key: 'hairdresser', label: 'Frisör', hidden: true },
        // { key: 'chef', label: 'Kock', hidden: true },
        // { key: 'masseur', label: 'Massör', hidden: true },
        // { key: 'dogGrooming', label: 'Hundtrimning', hidden: true },
        // { key: 'dogSitting', label: 'Hundpassning', hidden: true },
        // { key: 'bartender', label: 'Bartender', hidden: true },
        // { key: 'sommelier', label: 'Sommelier', hidden: true },
        // { key: 'personalTrainer', label: 'Personlig tränare', hidden: true },
        // { key: 'chiropractor', label: 'Kiropraktor', hidden: true },
        // { key: 'seniorCompanionship', label: 'Kamratskap för äldre', hidden: true },
        // { key: 'babySitting', label: 'Barnpassning', hidden: true },
      ],
    },
  },
  {
    id: 'dates-length',
    label: 'Datum & tid',
    type: 'BookingDateRangeLengthFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates', 'minDuration', 'timeSlot'],
    config: {
      // A global time zone to use in availability searches. As listings
      // can be in various time zones, we must decide what time zone we
      // use in search when looking for available listings within a
      // certain time interval.
      //
      // If you have all/most listings in a certain time zone, change this
      // config value to that.
      //
      // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
      searchTimeZone: 'Etc/UTC',

      // Options for the minimum duration of the booking
      options: [
        { key: '0', label: 'Vilken längd som helst' },
        { key: '30', label: '30 minuter', shortLabel: '30m' },
        { key: '45', label: '45 minuter', shortLabel: '45m' },
        { key: '60', label: '1 timme', shortLabel: '1h' },
        { key: '90', label: '1,5 timmar', shortLabel: '1.5h' },
        { key: '120', label: '2 timmar', shortLabel: '2h' },
      ],
    },
  },
  {
    id: 'price',
    label: 'Pris',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 50000,
      step: 1,
    },
  },
  {
    id: 'keyword',
    label: 'Nyckelord',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  {
    id: 'rating',
    label: 'Betyg',
    type: 'RatingFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    queryParamNames: ['meta_averageRating'],
    config: {
      options: [{ key: 1 }, { key: 2 }, { key: 3 }, { key: 4 }, { key: 5 }],
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    {
      key: '-price',
      label: 'Lowest price',
    },
    {
      key: 'price',
      label: 'Highest price',
    },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    {
      key: 'relevance',
      label: 'Relevance',
      longLabel: 'Relevance (Keyword search)',
    },
  ],
};

export const sortConfigInFinland = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Uusin' },
    { key: '-createdAt', label: 'Vanhin' },
    {
      key: '-price',
      label: 'Alin hinta',
    },
    {
      key: 'price',
      label: 'Korkein hinta',
    },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    {
      key: 'relevance',
      longLabel: 'Rajaa hakusanalla (hakusana)',
      label: 'Hakusana',
    },
  ],
};

export const sortConfigInSweden = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Nyaste' },
    { key: '-createdAt', label: 'Äldst' },
    {
      key: '-price',
      label: 'Lägsta pris',
    },
    {
      key: 'price',
      label: 'Högsta pris',
    },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    {
      key: 'relevance',
      label: 'Relevans',
      longLabel: 'Relevans (Sökordssökning) ',
    },
  ],
};

export const sessionLength = [
  {
    key: 30,
    label: '30 minutes',
  },
  {
    key: 45,
    label: '45 minutes',
  },
  {
    key: 60,
    label: '60 minutes',
  },
  {
    key: 90,
    label: '90 minutes',
  },
  {
    key: 120,
    label: '120 minutes',
  },
];

export const sessionLengthInFinland = [
  {
    key: 30,
    label: '30 minuuttia',
  },
  {
    key: 45,
    label: '45 minuuttia',
  },
  {
    key: 60,
    label: '60 minuuttia',
  },
  {
    key: 90,
    label: '90 minuuttia',
  },
  {
    key: 120,
    label: '120 minuuttia',
  },
];

export const sessionLengthInSweden = [
  {
    key: 30,
    label: '30 minuter',
  },
  {
    key: 45,
    label: '45 minuter',
  },
  {
    key: 60,
    label: '60 minuter',
  },
  {
    key: 90,
    label: '90 minuter',
  },
  {
    key: 120,
    label: '120 minuter',
  },
];

export const bookingOptions = [
  {
    key: 'instantBooking',
    label: 'Instant booking',
  },
  {
    key: 'requestToBook',
    label: 'Request to book',
  },
  {
    key: 'both',
    label: 'Both instant booking and request to book',
  },
];

export const bookingOptionsInFinland = [
  {
    key: 'instantBooking',
    label: 'Välitön varaus',
  },
  {
    key: 'requestToBook',
    label: 'Varauspyyntö',
  },
  {
    key: 'both',
    label: 'Sekä välitön varaus että varauspyyntö',
  },
];

export const bookingOptionsInSweden = [
  {
    key: 'instantBooking',
    label: 'Omedelbar bokning',
  },
  {
    key: 'requestToBook',
    label: 'Begäran att boka',
  },
  {
    key: 'both',
    label: 'Både omedelbar bokning och begäran att boka',
  },
];
