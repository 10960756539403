import { subscribeEmail } from '../../util/api';
import { storableError } from '../../util/errors';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

// ================ Action types ================ //

export const SUBSCRIBE_EMAIL_REQUEST = 'app/LandingPage/SUBSCRIBE_EMAIL_REQUEST';
export const SUBSCRIBE_EMAIL_SUCCESS = 'app/LandingPage/SUBSCRIBE_EMAIL_SUCCESS';
export const SUBSCRIBE_EMAIL_ERROR = 'app/LandingPage/SUBSCRIBE_EMAIL_ERROR';
export const RESET_SUCCEED_STATE = 'app/LandingPage/RESET_SUCCEED_STATE';

// ================ Reducer ================ //

const initialState = {
  subscribeInProgress: false,
  subscribeError: null,
  subscribeSucceed: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SUBSCRIBE_EMAIL_REQUEST:
      return { ...state, subscribeInProgress: true, subscribeSucceed: false, subscribeError: null };
    case SUBSCRIBE_EMAIL_SUCCESS:
      return { ...state, subscribeInProgress: false, subscribeSucceed: true };
    case SUBSCRIBE_EMAIL_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        subscribeInProgress: false,
        subscribeError: payload,
        subscribeSucceed: false,
      };
    case RESET_SUCCEED_STATE:
      return { ...state, subscribeSucceed: false };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const subscribeEmailRequest = () => ({ type: SUBSCRIBE_EMAIL_REQUEST });

export const subscribeEmailSuccess = () => ({ type: SUBSCRIBE_EMAIL_SUCCESS });

export const subscribeEmailError = e => ({
  type: SUBSCRIBE_EMAIL_ERROR,
  error: true,
  payload: e,
});

export const resetSucceedState = e => ({ type: RESET_SUCCEED_STATE });

// ================ Thunks ================ //

export const subscribeEmailToGoogleSheet = email => (dispatch, getState, sdk) => {
  dispatch(subscribeEmailRequest());
  const subscribedAt = moment().format('MMMM Do YYYY, h:mm:ss a');
  return subscribeEmail({ email, subscribedAt })
    .then(() => {
      dispatch(subscribeEmailSuccess());
    })
    .catch(e => {
      dispatch(subscribeEmailError(storableError(e)));
    });
};
