import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import moment from 'moment';
import { LINE_ITEM_NIGHT, LINE_ITEM_UNITS, propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';
import { getBookingTimeFromLocalToTimeZone } from '../../util/dates';

const BookingPeriod = props => {
  const { startDate, endDate, timeZone } = props;

  const timeZoneMaybe = timeZone ? timeZone : null;

  const dayInfor = getBookingTimeFromLocalToTimeZone(startDate, timeZoneMaybe, 'MMM DD');

  const startTime = getBookingTimeFromLocalToTimeZone(startDate, timeZoneMaybe, 'ddd HH:mm');

  const endTime = getBookingTimeFromLocalToTimeZone(endDate, timeZoneMaybe, 'ddd HH:mm');

  return (
    <>
      <div className={css.bookingPeriod}>
        <div className={css.bookingPeriodSection}>
          <div className={css.dayLabel}>
            <FormattedMessage id="BookingBreakdown.dateLabel" />
          </div>
        </div>
        <div className={css.bookingPeriodSectionRigth}>
          <div className={css.dayInfor}>
            <span>{dayInfor}</span>
          </div>
        </div>
      </div>
      <div className={css.bookingPeriod}>
        <div className={css.bookingPeriodSection}>
          <div className={css.timeLabel}>
            <FormattedMessage id="BookingBreakdown.timeLabel" />
          </div>
        </div>

        <div className={css.bookingPeriodSectionRigth}>
          <div className={css.timeInfor}>
            <span>{startTime}</span>
            <span>{endTime}</span>
          </div>
        </div>
      </div>
    </>
  );
};

const LineItemBookingPeriod = props => {
  const { booking, unitType, dateType, timeZone } = props;

  // Attributes: displayStart and displayEnd can be used to differentiate shown time range
  // from actual start and end times used for availability reservation. It can help in situations
  // where there are preparation time needed between bookings.
  // Read more: https://www.sharetribe.com/api-reference/marketplace.html#bookings
  const { start, end, displayStart, displayEnd } = booking.attributes;
  const localStartDate = displayStart || start;
  const localEndDateRaw = displayEnd || end;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isUnit = unitType === LINE_ITEM_UNITS;
  const endDay =
    isUnit || isNightly ? localEndDateRaw : moment(localEndDateRaw).subtract(1, 'days');

  return (
    <>
      <BookingPeriod
        startDate={localStartDate}
        endDate={endDay}
        dateType={dateType}
        timeZone={timeZone}
      />
      <hr className={css.totalDivider} />
    </>
  );
};
LineItemBookingPeriod.defaultProps = { dateType: null };

LineItemBookingPeriod.propTypes = {
  booking: propTypes.booking.isRequired,
  dateType: propTypes.dateType,
};

export default LineItemBookingPeriod;
