import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Logo, Modal } from '..';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { PrimaryButton } from '../Button/Button';
import css from './LocationModal.module.css';
import get from 'lodash/get';
import config from '../../config';
import Cookies from 'js-cookie';

const LocationModal = props => {
  const { onManageDisableScrolling, intl, className, rootClassName } = props;
  const [isOpen, setOpen] = useState(false);
  const { swedenDomain, swedenCountryCode, finlandDomain, finlandCountryCode } = config;

  useEffect(() => {
    const cookieLocation = Cookies.get('location');
    const origin = typeof window !== 'undefined' && get(window.location, 'origin', null);
    if (origin === swedenDomain || origin === finlandDomain) {
      return;
    }
    if (cookieLocation === swedenCountryCode && origin !== swedenDomain) {
      window.location.href = `${swedenDomain}${window.location.pathname}${window.location.search}`;
    } else if (cookieLocation === finlandCountryCode && origin !== finlandDomain) {
      window.location.href = `${finlandDomain}${window.location.pathname}${window.location.search}`;
    } else if (
      (cookieLocation === swedenCountryCode && origin === swedenDomain) ||
      (cookieLocation === finlandCountryCode && origin === finlandDomain)
    ) {
      return;
    } else {
      setOpen(true);
    }
  }, []);
  const classes = classNames(rootClassName || css.root, className);
  const onClose = () => {
    setOpen(false);
  };
  
  const handleRedirectOrigin = location => {
    if (location === swedenCountryCode) {
      Cookies.set('location', swedenCountryCode);
      window.location.href = `${swedenDomain}${window.location.pathname}`;
    } else if (location === finlandCountryCode) {
      Cookies.set('location', finlandCountryCode);
      window.location.href = `${finlandDomain}${window.location.pathname}`;
    }
  };

  const swedenBtn = intl.formatMessage({ id: 'LocationModal.SwedenButton' });
  const finlandBtn = intl.formatMessage({ id: 'LocationModal.FinlandButton' });
  return (
    <Modal
      id="LocationModal"
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      hideClose={true}
      onClose={()=>{}}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <Logo className={css.logo} />
      <p className={css.welcomeText}>
        <FormattedMessage id="LocationModal.welcomeText" />
      </p>
      <p className={css.chooseLocation}>
        <FormattedMessage id="LocationModal.chooseLocation" />
      </p>
      <PrimaryButton onClick={() => handleRedirectOrigin(swedenCountryCode)}>
        {swedenBtn}
      </PrimaryButton>
      <PrimaryButton
        className={css.secondBtn}
        onClick={() => handleRedirectOrigin(finlandCountryCode)}
      >
        {finlandBtn}
      </PrimaryButton>
    </Modal>
  );
};

export default injectIntl(LocationModal);
