import React from 'react';
import { string, bool, func, object, number } from 'prop-types';
import classNames from 'classnames';
import css from './FilterButton.module.css';

const FilterButton = props => {
  const { className, style, onClick, isSelected, labelMaxWidth, label } = props;

  const labelMaxWidthStyles = labelMaxWidth ? css.labelEllipsis : null;

  const labelStyles = isSelected ? css.labelSelected : css.label;

  return (
    <button
      className={classNames(className, labelStyles, labelMaxWidthStyles)}
      style={style}
      onClick={onClick}
    >
      <span>{label}</span>
    </button>
  );
};

FilterButton.defaultProps = {
  className: null,
  labelMaxWidth: 0,
  style: {},
  onClick: () => {},
};

FilterButton.propTypes = {
  isSelected: bool.isRequired,
  labelMaxWidth: number,
  className: string,
  style: object,
  onClick: func,
};

export default FilterButton;
