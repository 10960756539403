import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Page } from '../../components';
import FavoriteListResultsPanel from '../../components/FavoriteListResultsPanel/FavoriteListResultsPanel';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import css from './FavoritePage.module.css';
import { parse } from '../../util/urlHelpers';

const FavoritePageComponent = props => {
  const { intl } = props;
  const history = useHistory();

  const state = useSelector(state => state);
  const {
    pagination,
    fetchFavoriteListInProgress,
    fetchFavoriteListError,
    currentPageResultIds,
  } = state.FavoritePage;

  const pageListings = getListingsById(state, currentPageResultIds);

  const scrollingDisabled = isScrollingDisabled(state);

  const title = intl.formatMessage({ id: 'FavoritePage.title' });
  const healing = intl.formatMessage({ id: 'FavoritePage.healing' });
  const hasNoResult = pagination?.totalItems === 0;

  useEffect(() => {
    if (currentPageResultIds?.length === 0 && pagination?.totalItems > 0) {
      const { page = 1 } = parse(window.location.search);
      const newPage = page > 1 ? page - 1 : 1;
      history.push(`/favorite?page=${newPage}`);
    }
  }, [pagination]);

  return (
    <Page scrollingDisabled={scrollingDisabled} title={title}>
      <TopbarContainer className={css.topbar} currentPage="FavoritePage" />
      <div className={css.container}>
        <div className={css.healing}>{healing}</div>
        {fetchFavoriteListError ? (
          <h2 className={css.error}>
            <FormattedMessage id="FavoritePage.fetchError" />
          </h2>
        ) : null}
        {hasNoResult ? (
          <div className={css.noResults}>
            <FormattedMessage id="FavoritePage.noResults" />
          </div>
        ) : null}
        {fetchFavoriteListInProgress ? (
          <div className={css.loadingResults}>
            <FormattedMessage id="FavoritePage.loadingResults" />
          </div>
        ) : (
          <FavoriteListResultsPanel
            className={css.FavoriteListingsPanel}
            listings={pageListings}
            pagination={fetchFavoriteListInProgress ? null : pagination}
          />
        )}
      </div>
    </Page>
  );
};

FavoritePageComponent.propTypes = {
  // from injectIntl
  intl: intlShape.isRequired,
};

const FavoritePage = injectIntl(FavoritePageComponent);

export default FavoritePage;
