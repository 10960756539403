import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import locationSearch from './utils/locationSearch';
import { NamedLink } from '../../components';

import css from './SectionLocations.module.css';

import button1 from '../../assets/Button_1-min.png';
import button2 from '../../assets/Button_2-min.png';
import button3 from '../../assets/Button_3-min.png';
import button4 from '../../assets/Button_4-min.png';

const listButtons = [button1, button2, button3, button4];

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const locationLink = (name, image, searchQuery) => {
  const nameText = <span className={css.locationName}>{name}</span>;
  return (
    <NamedLink key={name} name="SearchPage" to={{ search: searchQuery }} className={css.location}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.locationImage} />
          <span className={css.imageTitle}>{name}</span>
        </div>
      </div>
    </NamedLink>
  );
};

const SectionLocations = props => {
  const { rootClassName, className, intl } = props;

  const classes = classNames(rootClassName || css.root, className);
  const seeAllMessage = intl.formatMessage({
    id: 'SectionLocations.seeAll',
  });
  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionLocations.title" />
      </div>
      <div className={css.locations}>
        {locationSearch.slice(0, 3).map((item, i) => {
          return locationLink(item.label, listButtons[i], `?pub_locations=${item.key}`);
        })}
        {locationLink(seeAllMessage, button4, '')}
      </div>
    </div>
  );
};

SectionLocations.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionLocations.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionLocations;
