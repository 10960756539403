import React, { Component } from 'react';
import { bool, func, node, number, object, string } from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from '../../util/reactIntl';

import {
  OutsideClickHandler,
  SearchFiltersSecondary,
  FilterComponent,
  FilterButton,
} from '../../components';
import css from './SecondaryFilter.module.css';

const KEY_CODE_ESCAPE = 27;

class SecondaryFilter extends Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false };
    this.filter = null;
    this.filterContent = null;

    this.handleBlur = this.handleBlur.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
    this.positionStyleForContent = this.positionStyleForContent.bind(this);
  }

  handleBlur() {
    this.setState({ isOpen: false });
  }

  handleKeyDown(e) {
    // Gather all escape presses to close menu
    if (e.keyCode === KEY_CODE_ESCAPE) {
      this.toggleOpen(false);
    }
  }

  toggleOpen(enforcedState) {
    if (enforcedState) {
      this.setState({ isOpen: enforcedState });
    } else {
      this.setState(prevState => ({ isOpen: !prevState.isOpen }));
    }
  }

  positionStyleForContent() {
    if (this.filter && this.filterContent) {
      // Render the filter content to the right from the menu
      // unless there's no space in which case it is rendered
      // to the left
      const distanceToRight = window.innerWidth - this.filter.getBoundingClientRect().right;
      const labelWidth = this.filter.offsetWidth;
      const contentWidth = this.filterContent.offsetWidth;
      const contentWidthBiggerThanLabel = contentWidth - labelWidth;
      const renderToRight = distanceToRight > contentWidthBiggerThanLabel;
      const contentPlacementOffset = this.props.contentPlacementOffset;

      const offset = renderToRight
        ? { left: contentPlacementOffset }
        : { right: contentPlacementOffset };
      // set a min-width if the content is narrower than the label
      const minWidth = contentWidth < labelWidth ? { minWidth: labelWidth } : null;

      return { ...offset, ...minWidth };
    }
    return {};
  }

  componentDidUpdate(prevProps, prevState) {
    const { isOpen } = this.state;

    if (prevState.isOpen !== isOpen) {
      this.props.setIsDarkerLayerOpen(isOpen);
    }
  }

  render() {
    const {
      rootClassName,
      className,
      id,
      label,
      labelMaxWidth,
      initialValues,
      urlQueryParams,
      getHandleChangedValueFn,
      listingsAreLoaded,
      applyFilters,
      cancelFilters,
      resetAll,
      secondaryFilters,
      selectedSecondaryFiltersCount,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);
    const popupClasses = classNames(css.popup, { [css.isOpen]: this.state.isOpen });
    const labelMaxWidthMaybe = labelMaxWidth ? { maxWidth: `${labelMaxWidth}px` } : {};
    const contentStyle = this.positionStyleForContent();

    const isSelected = selectedSecondaryFiltersCount > 0;

    return (
      <OutsideClickHandler onOutsideClick={this.handleBlur}>
        <div
          className={classes}
          onKeyDown={this.handleKeyDown}
          ref={node => {
            this.filter = node;
          }}
        >
          <FilterButton
            isSelected={isSelected}
            labelMaxWidth={labelMaxWidth}
            style={labelMaxWidthMaybe}
            onClick={() => this.toggleOpen()}
            label={label}
          />
          <div
            id={id}
            className={popupClasses}
            ref={node => {
              this.filterContent = node;
            }}
            style={contentStyle}
          >
            {this.state.isOpen ? (
              <SearchFiltersSecondary
                urlQueryParams={urlQueryParams}
                listingsAreLoaded={listingsAreLoaded}
                applyFilters={applyFilters}
                cancelFilters={cancelFilters}
                resetAll={resetAll}
                onClosePanel={() => this.setState({ isOpen: false })}
              >
                {secondaryFilters.map(config => {
                  return (
                    <FilterComponent
                      key={`SearchFiltersSecondary.${config.id}`}
                      idPrefix="SearchFiltersSecondary"
                      filterConfig={config}
                      urlQueryParams={urlQueryParams}
                      initialValues={initialValues}
                      getHandleChangedValueFn={getHandleChangedValueFn}
                      showAsPopup={false}
                    />
                  );
                })}
              </SearchFiltersSecondary>
            ) : null}
          </div>
        </div>
      </OutsideClickHandler>
    );
  }
}

SecondaryFilter.defaultProps = {
  rootClassName: null,
  className: null,
  popupClassName: null,
  onChange: null,
  initialValues: null,
  keepDirtyOnReinitialize: false,
  contentPlacementOffset: 0,
  liveEdit: false,
  label: null,
  labelMaxWidth: null,
};

SecondaryFilter.propTypes = {
  rootClassName: string,
  className: string,
  popupClassName: string,
  id: string.isRequired,
  onChange: func,
  initialValues: func,
  keepDirtyOnReinitialize: bool,
  contentPlacementOffset: number,
  label: string.isRequired,
  labelMaxWidth: number,
  isSelected: bool,
  children: node,

  // form injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(SecondaryFilter);
