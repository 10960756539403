import React from 'react';
import { bool, func, number, object, string } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { RangeSlider } from '../../components';
import { Field } from 'react-final-form';
import { FormattedMessage } from '../../util/reactIntl';

import css from './FieldTimeRange.module.css';

const DEBOUNCE_WAIT_TIME = 400;

//convert minute to hour
const convertToTimeOfDay = minuteValue => {
  let hour = parseInt(minuteValue / 60);
  const minute = parseInt(minuteValue - hour * 60);

  const convertStringTime = time => {
    if (time.toString().length === 1) {
      return `0${time}`;
    }

    return `${time}`;
  };
  return `${hour < 10 ? '0' + hour : hour}:${convertStringTime(minute)}`;
};

const parseTime = value => {
  const parsedValue = Number.parseInt(value, 10);
  if (isNaN(parsedValue)) {
    return '';
  }
  return convertToTimeOfDay(parsedValue);
};

const FilterTimeRangeFormComponent = props => {
  const { min, max, step, name, form, values } = props;
  const id = 'SearchPage';
  const { dates, minTimeRanges, maxTimeRanges } = values;

  return dates ? (
    <>
      <div className={css.contentWrapper}>
        <div className={css.title}>
          <FormattedMessage id="FieldTimeRange.titleTime" />
        </div>
        <div className={css.inputsWrapper}>
          <Field
            className={css.timeRange}
            name={name.minTimeRangesQueryParamName}
            component="input"
            type="text"
            placeholder={parseTime(min)}
            format={parseTime}
            min={min}
            max={max}
            step={step}
            parse={min}
          />
          <span>-</span>
          <Field
            className={css.timeRange}
            name={name.maxTimeRangesQueryParamName}
            component="input"
            type="text"
            placeholder={parseTime(max)}
            format={parseTime}
            min={min}
            max={max}
            step={step}
            parse={max}
          />
        </div>
      </div>
      <div className={css.sliderWrapper}>
        <RangeSlider
          min={min}
          max={max}
          step={step}
          handles={[minTimeRanges, maxTimeRanges]}
          onChange={handles => {
            form.change(name.minTimeRangesQueryParamName, handles[0]);
            form.change(name.maxTimeRangesQueryParamName, handles[1]);
          }}
          sliderUpdated={true}
          className={css.customRangebar}
        />
      </div>
    </>
  ) : null;
};

FilterTimeRangeFormComponent.defaultProps = {
  liveEdit: false,
  showAsPopup: false,
  isOpen: false,
  contentRef: null,
  style: null,
  min: 0,
  step: 1,
  onCancel: null,
  onChange: null,
  onClear: null,
  onSubmit: null,
};

FilterTimeRangeFormComponent.propTypes = {
  id: string.isRequired,
  liveEdit: bool,
  showAsPopup: bool,
  onCancel: func,
  onChange: func,
  onClear: func,
  onSubmit: func,
  isOpen: bool,
  contentRef: func,
  style: object,
  min: number.isRequired,
  max: number.isRequired,
  step: number,

  // form injectIntl
  intl: intlShape.isRequired,
};

const FilterTimeRange = injectIntl(FilterTimeRangeFormComponent);

export default FilterTimeRange;
