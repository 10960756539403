import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 24;
const MAX_PAGINATION_LIMIT_SUPPORTED = 10000;

const resultIds = data => data.data.map(l => l.id);

// ================ Action types ================ //

export const FAVORITE_LISTINGS_REQUEST = 'app/FavoritePage/FAVORITE_LISTINGS_REQUEST';
export const FAVORITE_LISTINGS_SUCCESS = 'app/FavoritePage/FAVORITE_LISTINGS_SUCCESS';
export const FAVORITE_LISTINGS_ERROR = 'app/FavoritePage/FAVORITE_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  fetchFavoriteListInProgress: false,
  fetchFavoriteListError: null,
  currentPageResultIds: [],
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FAVORITE_LISTINGS_REQUEST:
      return {
        ...state,
        fetchFavoriteListInProgress: true,
        fetchFavoriteListError: null,
      };
    case FAVORITE_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        fetchFavoriteListInProgress: false,
      };
    case FAVORITE_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, fetchFavoriteListInProgress: false, fetchFavoriteListError: payload };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const fetchFavoriteListRequest = () => ({
  type: FAVORITE_LISTINGS_REQUEST,
});

export const fetchFavoriteListSuccess = response => ({
  type: FAVORITE_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const fetchFavoriteListError = e => ({
  type: FAVORITE_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const calculatePagination = page => async (dispatch, getState, sdk) => {
  try {
    await dispatch(fetchCurrentUser());
    const { currentUser } = getState().user;
    const favoriteList = currentUser?.attributes?.profile?.privateData?.favoriteList?.own || [];
    const totalItems = favoriteList.length;

    const favoriteListByPage = favoriteList.slice(
      (page - 1) * RESULT_PAGE_SIZE,
      RESULT_PAGE_SIZE * page
    );

    const totalPages =
      Math.floor(totalItems / RESULT_PAGE_SIZE) + (totalItems % RESULT_PAGE_SIZE > 0 ? 1 : 0);

    const paginationLimit =
      totalPages - page > MAX_PAGINATION_LIMIT_SUPPORTED
        ? page + MAX_PAGINATION_LIMIT_SUPPORTED
        : totalPages;

    const meta = {
      page,
      paginationLimit,
      totalPages,
      perPage: RESULT_PAGE_SIZE,
      totalItems,
    };

    return { meta, favoriteListByPage };
  } catch (e) {
    log.error(e, 'calculate-pagination-favorite-state-failed');
    throw e;
  }
};

export const fetchFavoriteList = page => async (dispatch, getState, sdk) => {
  try {
    dispatch(fetchFavoriteListRequest());

    const { meta, favoriteListByPage } = await dispatch(calculatePagination(page));

    const params = {
      ids: favoriteListByPage,
      include: ['author', 'images'],
      'fields.listing': ['title', 'geolocation', 'price', 'publicData', 'metadata'],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
      'limit.images': 1,
    };

    const response = await sdk.listings.query(params);
    response.data.meta = meta;
    dispatch(addMarketplaceEntities(response));
    dispatch(fetchFavoriteListSuccess(response));
    return response;
  } catch (e) {
    log.error(e, 'fetch-favorite-state-failed');
    dispatch(fetchFavoriteListError(storableError(e)));
  }
};

export const loadData = (params, search) => async (dispatch, getState, sdk) => {
  const { page = 1 } = parse(search);
  return await dispatch(fetchFavoriteList(page));
};
