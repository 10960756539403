import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import {
  formatDateStringToTz,
  getExclusiveEndDateWithTz,
  convertNumberToTime,
} from '../../util/dates';
import { parse } from '../../util/urlHelpers';
import useMarketplaceConfigByLocation from '../../util/hooks/useMarketplaceConfigByLocation';
import { types as sdkTypes } from '../../util/sdkLoader';
import { isEmpty, get, unionWith, chunk } from 'lodash';
import moment from 'moment';
import momentTimeZone from 'moment-timezone';
import { loginError } from '../../ducks/Auth.duck';

const { UUID } = sdkTypes;

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 24;

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/SearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  searchMapListingIds: [],
  searchMapListingsError: null,
  listingsReviews: null,
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_MAP_LISTINGS_REQUEST:
      return {
        ...state,
        searchMapListingsError: null,
      };
    case SEARCH_MAP_LISTINGS_SUCCESS: {
      const searchMapListingIds = unionWith(
        state.searchMapListingIds,
        resultIds(payload.data),
        (id1, id2) => id1.uuid === id2.uuid
      );
      return {
        ...state,
        searchMapListingIds,
      };
    }
    case SEARCH_MAP_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchMapListingsError: payload };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchMapListingsRequest = () => ({ type: SEARCH_MAP_LISTINGS_REQUEST });

export const searchMapListingsSuccess = response => ({
  type: SEARCH_MAP_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchMapListingsError = e => ({
  type: SEARCH_MAP_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const handleListParam = (datesParam, minDurationParam, currentParams) => {
  const dateValues = datesParam ? datesParam.split(',') : [];
  const hasDateValues = datesParam && dateValues.length === 2;
  const startDate = hasDateValues ? dateValues[0] : null;
  const endDate = hasDateValues ? dateValues[1] : null;

  const minDurationMaybe =
    minDurationParam && Number.isInteger(minDurationParam) && hasDateValues
      ? { minDuration: minDurationParam }
      : {};

  // Find configs for 'dates-length' filter
  // (type: BookingDateRangeLengthFilter)
  const filterConfigs = useMarketplaceConfigByLocation.custom.filters;
  const idOfBookingDateRangeLengthFilter = 'dates-length';
  const dateLengthFilterConfig = filterConfigs.find(f => f.id === idOfBookingDateRangeLengthFilter);
  // Extract time zone
  const timeZone = momentTimeZone.tz.guess() || dateLengthFilterConfig.config.searchTimeZone;
  const listParams = [];

  let dayLoop = moment(startDate).clone();
  if (hasDateValues) {
    const { timeSlot } = currentParams;
    const [minTimeRange, maxTimeRange] = timeSlot.split(',');
    const checkEndDate = moment(endDate)
      .clone()
      .add(1, 'days');
    while (!moment(dayLoop).isSame(checkEndDate)) {
      const start = convertNumberToTime(dayLoop, timeZone, minTimeRange);
      const end = convertNumberToTime(dayLoop, timeZone, maxTimeRange);
      const params = {
        ...currentParams,
        start,
        end,
        availability: 'time-partial',
        ...minDurationMaybe,
        page: 1,
      };
      listParams.push(params);
      dayLoop = moment(dayLoop).add(1, 'days');
    }
  }

  return listParams;
};

export const searchListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));

  const priceSearchParams = priceParam => {
    const inSubunits = value =>
      convertUnitToSubUnit(value, unitDivisor(useMarketplaceConfigByLocation.currencyConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
          price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
        }
      : {};
  };
  // detect listing for each country
  const countryCode = useMarketplaceConfigByLocation.currentCountryCode;

  
  const { perPage, price, dates, minDuration, bounds, ...rest } = searchParams;
  const priceMaybe = priceSearchParams(price);

  const params = {
    ...rest,
    ...priceMaybe,
    pub_countryCode: countryCode,
    bounds: null,
    per_page: perPage,
  };

  const dateValues = dates ? dates.split(',') : [];
  const hasDateValues = dates && dateValues.length === 2;

  if (!hasDateValues) {
    return sdk.listings
      .query(params)
      .then(async response => {
        dispatch(addMarketplaceEntities(response));
        dispatch(searchListingsSuccess(response));
        return response;
      })
      .catch(e => {
        dispatch(searchListingsError(storableError(e)));
        throw e;
      });
  } else {
    const listParam = handleListParam(dates, minDuration, params);
    const listPromise = listParam.map(item => {
      return sdk.listings.query(item);
    });
    return Promise.all(listPromise)
      .then(response => {
        const allListing = unionWith(
          response.reduce((arr, cur) => {
            return arr.concat(get(cur, 'data.data', []));
          }, []),
          (l1, l2) => get(l1, 'id.uuid') === get(l2, 'id.uuid')
        );

        const allInclude = unionWith(
          response.reduce((arr, cur) => {
            return arr.concat(get(cur, 'data.included', []));
          }, []),
          (l1, l2) => get(l1, 'id.uuid') === get(l2, 'id.uuid')
        );

        const { page } = rest || 1;
        const availabilityListings = chunk(allListing, perPage);

        const newResponse = {
          data: {
            data: availabilityListings[page - 1] ? availabilityListings[page - 1] : [],
            included: allInclude,
            meta: {
              page: page,
              perPage: perPage,
              totalItems: allListing.length,
              totalPages: availabilityListings.length,
            },
          },
        };

        dispatch(addMarketplaceEntities(newResponse));
        dispatch(searchListingsSuccess(newResponse));
        return newResponse;
      })
      .catch(e => {
        dispatch(searchListingsError(storableError(e)));
        throw e;
      });
  }
};

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

export const searchMapListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchMapListingsRequest(searchParams));

  const { perPage, ...rest } = searchParams;
  const params = {
    ...rest,
    per_page: perPage,
  };

  return sdk.listings
    .query(params)
    .then(async response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchMapListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchMapListingsError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = useMarketplaceConfigByLocation.sortSearchByDistance && origin ? { origin } : {};
  return searchListings({
    ...rest,
    ...originMaybe,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['author', 'images'],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData', 'metadata'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
  });
};
