import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import css from './SectionHero.module.css';
import { HeroSearchBarForm } from '../../forms';
import config from '../../config';
import useLanguageByLocation from '../../util/hooks/useLanguageByLocation';

const SectionHero = props => {
  const { rootClassName, className, intl, history } = props;

  const classes = classNames(rootClassName || css.root, className);

  const currentCountryCode = useLanguageByLocation().countryCode;

  // becase title in finnish is long, css.longTitle increase the width
  const titleClassName = classNames(css.heroMainTitle, {
    [css.longTitle]: currentCountryCode === config.finlandCountryCode,
  });

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <h1 className={titleClassName}>
          <FormattedMessage id="SectionHero.title" />
        </h1>
        <h2 className={css.heroSubTitle}>
          <FormattedMessage id="SectionHero.subTitle" />
        </h2>
        <HeroSearchBarForm animation history={history} intl={intl} />
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
