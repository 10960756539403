import React from 'react';
import { bool } from 'prop-types';
import RatingFilterPlain from './RatingFilterPlain';
import RatingFilterPopup from './RatingFilterPopup';

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

const RatingFilter = props => {
  const { showAsPopup, queryParamNames, onSubmit, name, initialValues, ...rest } = props;

  const queryParamNamesValue = getQueryParamName(queryParamNames);
  const isSelected = !!initialValues && !!initialValues[queryParamNamesValue];

  const namedInitValue = {
    [name]:
      initialValues && initialValues[queryParamNamesValue]
        ? initialValues[queryParamNamesValue] / 100
        : null,
  };

  const handleSubmit = values => {
    const formatValue = values ? `${values[name] * 100},` : null;
    onSubmit({ [queryParamNamesValue]: formatValue });
  };

  return showAsPopup ? (
    <RatingFilterPopup
      name={name}
      isSelected={isSelected}
      onSubmit={handleSubmit}
      initialValues={namedInitValue}
      {...rest}
    />
  ) : (
    <RatingFilterPlain
      name={name}
      isSelected={isSelected}
      onSubmit={handleSubmit}
      initialValues={namedInitValue}
      {...rest}
    />
  );
};

RatingFilter.defaultProps = {
  showAsPopup: false,
};

RatingFilter.propTypes = {
  showAsPopup: bool,
};

export default RatingFilter;
