import { currencyConfiguration } from '../../currency-config';
import useLanguageByLocation from './useLanguageByLocation';
import config from '../../config';
import defaultLocationSearches from '../../default-location-searches';
import * as custom from '../../marketplace-custom-config.js';

const currentCountryCode = useLanguageByLocation().countryCode;

// Canonical root url is needed in social media sharing and SEO optimization purposes.
const canonicalRootURL = process.env.REACT_APP_CANONICAL_ROOT_URL;

// It should match one of the currencies listed in currency-config.js
// Marketplace currency.

const finlandCurrency = process.env.REACT_APP_SHARETRIBE_MARKETPLACE_CURRENCY_FINLAND;
const swedenCurrency = process.env.REACT_APP_SHARETRIBE_MARKETPLACE_CURRENCY_SWEDEN;

const currencyConf =
  currentCountryCode === config.swedenCountryCode
    ? swedenCurrency
    : currentCountryCode === config.finlandCountryCode
    ? finlandCurrency
    : swedenCurrency;

const currency = currencyConf ? currencyConf.toUpperCase() : currencyConf;

// Currency formatting options.
// See: https://github.com/yahoo/react-intl/wiki/API#formatnumber
const currencyConfig = currencyConfiguration(currency);

const bookingProcessAlias = 'flex-hourly-default-process/release-1';

const customConfigWithLanguage =
  currentCountryCode === config.swedenCountryCode
    ? {
        ...custom,
        filters: custom.filtersInSweden,
        sessionLength: custom.sessionLengthInSweden,
        bookingOptions: custom.bookingOptionsInSweden,
        sortConfig: custom.sortConfigInSweden,
      }
    : currentCountryCode === config.finlandCountryCode
    ? {
        ...custom,
        filters: custom.filtersInFinland,
        sessionLength: custom.sessionLengthInFinland,
        bookingOptions: custom.bookingOptionsInFinland,
        sortConfig: custom.sortConfigInFinland,
      }
    : custom;

// If you want to change the language, remember to also change the
// locale data and the messages in the app.js file.
const locale =
  currentCountryCode === config.swedenCountryCode
    ? 'se'
    : currentCountryCode === config.finlandCountryCode
    ? 'fi'
    : 'en';

const currentUrl =
  currentCountryCode === config.swedenCountryCode
    ? process.env.REACT_APP_HUBISTA_DOMAIN_SWEDEN_URL
    : process.env.REACT_APP_HUBISTA_DOMAIN_FINLAND_URL;

const maps = {
  mapboxAccessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  googleMapsAPIKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,

  // The location search input can be configured to show default
  // searches when the user focuses on the input and hasn't yet typed
  // anything. This reduces typing and avoids too many Geolocation API
  // calls for common searches.
  search: {
    // When enabled, the first suggestion is "Current location" that
    // uses the browser Geolocation API to query the user's current
    // location.
    suggestCurrentLocation: process.env.REACT_APP_DEFAULT_SEARCHES_ENABLED === 'true',

    // Distance in meters for calculating the bounding box around the
    // current location.
    currentLocationBoundsDistance: 1000,

    // Example location can be edited in the
    // `default-location-searches.js` file.
    defaults:
      process.env.REACT_APP_DEFAULT_SEARCHES_ENABLED === 'true' ? defaultLocationSearches : [],

    // Limit location autocomplete to a one or more countries
    // using ISO 3166 alpha 2 country codes separated by commas.
    // If you want to limit the autocomplete, uncomment this value:
    countryLimit: ['SE', 'FI'],
  },

  // When fuzzy locations are enabled, coordinates on maps are
  // obfuscated randomly around the actual location.
  //
  // NOTE: This only hides the locations in the UI level, the actual
  // coordinates are still accessible in the HTTP requests and the
  // Redux store.
  fuzzy: {
    enabled: true,

    // Amount of maximum offset in meters that is applied to obfuscate
    // the original coordinates. The actual value is random, but the
    // obfuscated coordinates are withing a circle that has the same
    // radius as the offset.
    offset: 500,

    // Default zoom level when showing a single circle on a Map. Should
    // be small enough so the whole circle fits in.
    defaultZoomLevel: 10,

    // Color of the circle on the Map component.
    circleColor: '#0196B3',
  },

  // Custom marker image to use in the Map component.
  //
  // NOTE: Not used if fuzzy locations are enabled.
  customMarker: {
    enabled: false,

    // Publicly accessible URL for the custom marker image.
    //
    // The easiest place is /public/static/icons/ folder, but then the
    // marker image is not available while developing through
    // localhost.
    url: encodeURI(`${canonicalRootURL}/static/icons/map-marker-32x32.png`),

    // Dimensions of the marker image.
    width: 32,
    height: 32,

    // Position to anchor the image in relation to the coordinates,
    // ignored when using Mapbox.
    anchorX: 16,
    anchorY: 32,
  },
};

const bookingUnitType = 'line-item/units';

const listingMinimumPriceSubUnits = 0;
const listingMaximumPriceSubUnits = {
  EUR: 500000,
  SEK: 5000000,
};

export default {
  currencyConfig,
  currencyConf,
  bookingProcessAlias,
  custom: customConfigWithLanguage,
  locale,
  maps,
  currency,
  listingMinimumPriceSubUnits,
  listingMaximumPriceSubUnits,
  bookingUnitType,
  currentCountryCode,
  finlandCurrency,
  swedenCurrency,
  canonicalRootURL,
  currentCountryCode,
  currentUrl,
};
