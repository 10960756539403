import React, { useState } from 'react';
import { injectIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import * as validators from '../../util/validators';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { propTypes } from '../../util/types';
import { compose } from 'redux';
import css from './SubscribeNewsletterForm.module.css';
import { Form, Button, FieldTextInput, IconArrowHead } from '../../components';
import isEqual from 'lodash/isEqual';

const SubscribeNewsletterFormComponent = props => {
  const [submittedValues, setSubmittedValues] = useState({});

  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          className,
          rootClassName,
          disabled,
          handleSubmit,
          intl,
          formId,
          invalid,
          subscribeInProgress,
          subscribeError,
          subscribeSucceed,
          values,
          form,
          onResetSucceedState,
        } = fieldRenderProps;

        const submittedOnce = Object.keys(submittedValues).length > 0;
        const pristineSinceLastSubmit =
          !subscribeError && submittedOnce && isEqual(values, submittedValues);
        const submitDisabled = invalid || pristineSinceLastSubmit || subscribeInProgress;
        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = subscribeInProgress;
        const emailPlaceholder = intl.formatMessage({
          id: 'SubscribeNewsletterForm.emailPlaceholder',
        });
        const emailRequiredMessage = intl.formatMessage({
          id: 'SubscribeNewsletterForm.emailRequired',
        });
        const emailRequired = validators.required(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({
          id: 'SubscribeNewsletterForm.emailInvalid',
        });
        const emailValid = validators.emailFormatValid(emailInvalidMessage);

        if (subscribeSucceed) {
          return (
            <div className={css.successMessage}>
              <h4 className={css.thanksMessage}>
                <FormattedMessage id="SubscribeNewsletterForm.thanks" />
              </h4>
              <p>
                <FormattedMessage id="SubscribeNewsletterForm.fromNow" />
              </p>
            </div>
          );
        }
        const submitNewsletter = e => {
          handleSubmit(e).then(() => {
            setSubmittedValues(values);
          });
        };
        return (
          <>
            {subscribeError && (
              <p className={css.submitError}>
                <FormattedMessage id="SubscribeNewsletterForm.error" />
              </p>
            )}
            <Form className={classes} onSubmit={submitNewsletter}>
              <FieldTextInput
                className={css.reviewContent}
                type="text"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                placeholder={emailPlaceholder}
                className={css.emailField}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={pristineSinceLastSubmit}
                spinnerClassName={css.spinner}
                checkmarkClassName={css.checkmark}
              >
                <IconArrowHead className={css.arrow} direction="right" />
              </Button>
            </Form>
          </>
        );
      }}
    />
  );
};

SubscribeNewsletterFormComponent.defaultProps = {
  className: null,
  rootClassName: null,
  subscribeError: null,
  subscribeInProgress: false,
};

const { bool, func, string } = PropTypes;

SubscribeNewsletterFormComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  subscribeInProgress: bool.isRequired,
  subscribeSucceed: bool.isRequired,
  subscribeError: propTypes.error,
};

const SubscribeNewsletterForm = compose(injectIntl)(SubscribeNewsletterFormComponent);
SubscribeNewsletterForm.displayName = 'SubscribeNewsletterForm';

export default SubscribeNewsletterForm;
