import React, { Component, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '../../components';
import { EditListingLocationForm } from '../../forms';
import get from 'lodash/get';

import css from './EditListingLocationPanel.module.css';
import defaultLocations from '../../default-location-searches';

class EditListingLocationPanel extends Component {
  constructor(props) {
    super(props);

    this.getInitialValues = this.getInitialValues.bind(this);
    this.initialValues = null;
  }

  componentDidMount() {
    this.getInitialValues();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.listing.id !== this.props.listing.id) {
      this.getInitialValues();
    }
  }

  getInitialValues = () => {
    const { listing } = this.props;
    const currentListing = ensureOwnListing(listing);
    const { publicData = {} } = currentListing.attributes;
    const locations = get(publicData, 'locations', [null]);

    this.initialValues = { locations };
  };

  render() {
    const {
      className,
      rootClassName,
      listing,
      disabled,
      ready,
      onSubmit,
      onChange,
      panelUpdated,
      updateInProgress,
      errors,
      textNextButton,
      textPreviousButton,
      isNewListingFlow,
      onGoBack,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);
    const { publicData = {} } = currentListing.attributes;
    const { cities = [] } = publicData;

    const isPublished =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const panelTitle = isPublished ? (
      <FormattedMessage
        id="EditListingLocationPanel.title"
        values={{
          listingTitle: (
            <ListingLink listing={listing}>
              <FormattedMessage id="EditListingLocationPanel.listingTitle" />
            </ListingLink>
          ),
        }}
      />
    ) : (
      <FormattedMessage id="EditListingLocationPanel.createListingTitle" />
    );
    const panelDescription = <FormattedMessage id="EditListingLocationPanel.description" />;

    return (
      <div className={classes}>
        <h1 className={css.title}>{panelTitle}</h1>
        <p className={css.description}>{panelDescription}</p>
        <EditListingLocationForm
          className={css.form}
          initialValues={this.initialValues}
          onSubmit={values => {
            const { locations } = values;
            const updateValues = {
              geolocation: null,
              publicData: {
                locations,
              },
            };

            onSubmit(updateValues);
          }}
          onChange={onChange}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
          textNextButton={textNextButton}
          textPreviousButton={textPreviousButton}
          isNewListingFlow={isNewListingFlow}
          onGoBack={onGoBack}
        />
      </div>
    );
  }
}

const { func, object, string, bool } = PropTypes;

EditListingLocationPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingLocationPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  textNextButton: string.isRequired,
  textPreviousButton: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingLocationPanel;
