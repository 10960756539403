import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import youtubeIcon from '../../assets/icons/youtube_icon.png';

import css from './IconSocialMediaYoutube.module.css';

const IconSocialMediaYoutube = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return <img src={youtubeIcon} className={classes} />;
};

IconSocialMediaYoutube.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaYoutube.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaYoutube;
