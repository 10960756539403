import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { Button, FieldSelect, FieldTextInput, Form } from '../../components';

import css from './EditListingFeaturesForm.module.css';
import { useIntl } from 'react-intl/dist/react-intl';
import { required } from '../../util/validators';
import config from '../../util/hooks/useMarketplaceConfigByLocation';

const EditListingFeaturesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        handleSubmit,
        pristine,
        textNextButton,
        textPreviousButton,
        isNewListingFlow,
        onGoBack,
        updated,
        updateInProgress,
        fetchErrors,
      } = formRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress;
      const intl = useIntl();

      const serviceLabel = intl.formatMessage({
        id: 'EditListingFeaturesForm.serviceLabel',
      });
      const servicePlaceholder = intl.formatMessage({
        id: 'EditListingFeaturesForm.servicePlaceholder',
      });
      const serviceRequiredMessage = intl.formatMessage({
        id: 'EditListingFeaturesForm.serviceRequired',
      });

      const sessionLengthLabel = intl.formatMessage({
        id: 'EditListingFeaturesForm.sessionLengthLabel',
      });
      const sessionLengthPlaceholder = intl.formatMessage({
        id: 'EditListingFeaturesForm.sessionLengthPlaceholder',
      });
      const sessionLengthRequiredMessage = intl.formatMessage({
        id: 'EditListingFeaturesForm.sessionLengthRequired',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
        </p>
      ) : null;

      const handleAddMoreService = fields => () => {
        fields.push('');
      };

      const handleRemoveMoreService = (fields, index) => () => {
        fields.remove(index);
      };

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <FieldArray name="services">
            {({ fields }) => (
              <div>
                {fields.map((name, index) => (
                  <>
                    <div key={name} className={classNames(css.field, css.serviceItem)}>
                      <FieldTextInput
                        id={`${name}.service`}
                        name={`${name}.service`}
                        className={css.select}
                        type="text"
                        label={serviceLabel}
                        placeholder={servicePlaceholder}
                        maxLength={200}
                        validate={required(serviceRequiredMessage)}
                      />
                      <Button
                        className={css.addMoreService}
                        onClick={handleAddMoreService(fields)}
                        type="button"
                      >
                        +
                      </Button>
                      {index !== 0 && (
                        <Button
                          className={css.removeService}
                          onClick={handleRemoveMoreService(fields, index)}
                          type="button"
                        >
                          -
                        </Button>
                      )}
                    </div>
                    <FieldSelect
                      className={css.field}
                      id={`${name}.sessionLength`}
                      label={sessionLengthLabel}
                      name={`${name}.sessionLength`}
                      validate={required(sessionLengthRequiredMessage)}
                    >
                      <option value="" disabled>
                        {sessionLengthPlaceholder}
                      </option>
                      {config.custom.sessionLength.map(option => (
                        <option value={option.key} key={option.key}>
                          {intl.formatMessage({ id: `SessionLength.label.${option.key}` })}
                        </option>
                      ))}
                    </FieldSelect>
                  </>
                ))}
              </div>
            )}
          </FieldArray>

          <div className={classNames({ [css.wrapperButton]: isNewListingFlow })}>
            {isNewListingFlow && (
              <Button
                className={classNames(css.submitButton, { [css.buttonActive]: !submitDisabled })}
                type="button"
                disabled={submitDisabled}
                ready={submitReady}
                onClick={onGoBack}
              >
                {textPreviousButton}
              </Button>
            )}
            <Button
              className={classNames(css.submitButton, { [css.buttonActive]: !submitDisabled })}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {textNextButton}
            </Button>
          </div>
        </Form>
      );
    }}
  />
);

EditListingFeaturesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
};

EditListingFeaturesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  onSubmit: func.isRequired,
  textNextButton: string.isRequired,
  textPreviousButton: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

const EditListingFeaturesForm = EditListingFeaturesFormComponent;

export default EditListingFeaturesForm;
