import React from 'react';
import { FormattedMessage } from 'react-intl/dist/react-intl';
import css from './BookingBreakdown.module.css';

const ListingService = ({ serviceName }) => {
  return (
    <>
      <div className={css.listingService}>
        <div className={css.listingServiceTitle}>
          <FormattedMessage id="BookingBreakdown.listingService" />
        </div>
        <div className={css.listingServiceName}>{serviceName}</div>
      </div>
    </>
  );
};

export default ListingService;
