import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last Updated: November 1, 2021</p>

      <h2>1. Introduction</h2>
      <p>
        Hubista provides a platform where sellers can promote their services within predefined
        service categories and where buyers can browse for these services and make a booking (buyers
        and sellers are jointly referred to as users). For the functionality of the platform Hubista
        has to gather certain personal information from users. This privacy policy describes how
        Hubista will gather, use and maintain the personal information collected via the platform.
        By using the Hubista platform users confirm that they have read and understood this privacy
        policy.
      </p>
      <p>
        The integrity of the users of this platform is of highest importance to us and we are
        responsible for the personal information you provide us with. It is important that you
        understand this policy and that you feel confident with the way we handle your personal
        information.
      </p>
      <h2>2. How do we access personal data</h2>
      <p>
        To use the platform users have to create an account. Depending on whether you are a seller
        or a buyer the gathered information may vary. In addition to the information users provide
        us with, Hubista may also collect personal information via a third party provider. Such
        collected information may be of the same character as the one left to Hubista by users such
        as: contact information, which may include but not limited to first- and surname, postal
        address, and email address.
      </p>

      <h2>3. What personal data do we process and how do we process it</h2>
      <p>
        Hubista primarily processes personal data such as: contact details such as name, address,
        and email address. We process personal data for administrative, book keeping and marketing
        purposes. Without the collection of personal information the Hubista platform would not
        work, i.e. buyers and sellers would not be able to enter an agreement. Hence, the legal
        ground for collecting this information is to facilitate the functionality of the platform.
        Most of the collected personal information is provided to Hubista by the users. However,
        some information might be collected by Hubista via an external source as explained above.
        Hubista strives to collect just the necessary information and keep that on a minimal level.
      </p>
      <h2>4. Information retention</h2>
      <p>
        Hubista retains your personal data for as long as you are a user. Some personal data might
        be stored for a longer period of time in order to fulfill our obligations with regards to
        book keeping obligations.
      </p>
      <h2>5. Information retention</h2>
      <p>
        The starting point for Hubista regarding information sharing is that we should not share
        personal information to a third party unless it is required to fulfill our obligations
        according to an agreement or according to applicable law.
      </p>
      <p>
        The platform is designed to minimize the sharing of sensitive information between buyers and
        sellers. However, a certain degree of information is required to make the physical meeting
        possible. Such information needs to be handled with care and respect to the personal
        integrity of the user. Hubista can however, at no time be held responsible for any incidents
        that might occur due to leakage of any information that has been shared between users on the
        platform
      </p>
      <h2>6. Data protection</h2>
      <p>
        Hubista purchases all storage and its connected infrastructure via a third party contractor.
        This contractor takes data security very seriously and security is a conscious part of their
        product development and operation processes. It strives to follow reasonable industry best
        practices in order to ensure that data is kept safe and risk of data exposure is limited.
        Data security and maintenance of data is taken into account in all parts of data handling:
        collection, storage, management, access and eventually deletion of the data when that is
        requested or the data is no longer needed. This is achieved with the following actions:
      </p>
      <ul className={css.listText}>
        <li>
          Encrypted communication channels are always used when data is transmitted over a public
          network
        </li>
        <li>
          Passwords are always stored securely hashed with a cryptographically secure one-way
          hashing function
        </li>
        <li>
          Other pieces of sensitive data may be additionally encrypted on application level (such as
          customer Stripe API keys, for instance)
        </li>
        <li>
          Access to data is granted only to systems or personnel that need the access in order to
          function or perform their work
        </li>
        <li>
          Access is granted in accordance with the least privileges principle - i.e. only the the
          amount of access that is required is given and further access is denied by default.
        </li>
        <li>
          Deleting (or using pseudonymization when deletion is not practically possible) the data
          when data subject requests that or it has become sure that the data is no longer needed.
        </li>
      </ul>
      <p>
        Data access is controlled on multiple levels (network, application, user, etc), using 2
        appropriate security mechanisms provided by the infrastructure or service provider. For
        instance, for data stored in AWS, AWS IAM policies and network security groups are heavily
        utilized
      </p>
      <h2>7. Regarding cookies</h2>
      <p>
        Hubista uses cookies to improve the user experience on the platform. Cookies improve the
        functionality and usability of the platform. Hubista also uses cookies to collect and
        analyse behavioural data based on your usage of the platform to increase the user experience
        and to facilitate individualized communication and messages to our customers. We also use
        cookies to enable targeted marketing.
      </p>
      <div className={css.child}>
        <h3>
          <span>7.1.</span> How users can handle cookies
        </h3>
        <p>
          You can change your settings for the use and extent of cookies in your browser. All
          cookies can be blocked, selected ones can be accepted or cookies can be deleted upon
          turning off the browser. If you choose to block or delete cookies that can imply limited
          functionality of the platform.
        </p>
      </div>
      <h2>8. Customer rights with regards to personal information</h2>
      <p>As a user of the platform you are entitled to:</p>
      <ul className={css.listText}>
        <li>
          Request an extract from the register where you can see what personal information we have
          collected about you.
        </li>
        <li>
          Call for changes if any of the stored personal data about you is incorrect or not complete
        </li>
        <li>
          Have your personal data deleted if if the following conditions are at hand:
          <ul className={css.listTextChild}>
            <li>The information is no longer needed for the purpose for which it was collected</li>
            <li>If the data is stored with your consent and you revoke the consent</li>
            <li>
              If the processing is based on a balance of interests and there are no justified
              reasons which outweigh your interest.
            </li>
            <li>If the personal data has been processed illegally</li>
            <li>If deletion is required to fulfil a legal obligation</li>
            <li>If you object to process for direct marketing purposes</li>
            <p>
              The right to have personal data deleted does not apply if Hubista is obliged by law to
              store the data.
            </p>
          </ul>
        </li>
        <li>
          Data portability (the right to have your personal data transferred) provided that the
          legal basis is consent or agreement and what you can obtain is personal data concerning
          you, provided by yourself or by your actions or activities.
        </li>
        <li>
          Request that the processing of your personal data should be restricted. If however such a
          restriction is requested that may imply that Hubista cannot fulfill our obligations to you
          during the time the restriction is active.
        </li>
        <li>
          Object to a processing of personal data that has a balance of interests as a legal basis.
          In order for us to be able to continue with the current process, we need to be able to
          show a compelling justified reason for the processing that outweighs your interests,
          rights or freedoms. Otherwise, we may only process the data in order to establish,
          exercise or defend legal claims.
        </li>
        <li>
          Object to your personal data being used for direct marketing. If an objection is made to
          direct marketing, the personal data may no longer be processed for such purposes.
        </li>
      </ul>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
