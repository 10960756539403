import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { propTypes } from '../../util/types';
import { ListingCard, PaginationLinks } from '..';
import css from './FavoriteListResultsPanel.module.css';

const FavoriteListResultsPanel = props => {
  const { className, rootClassName, listings, pagination } = props;
  const classes = classNames(rootClassName || css.root, className);

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks className={css.pagination} pageName="FavoritePage" pagination={pagination} />
    ) : null;

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  return (
    <div className={classes}>
      <div className={css.listingCards}>
        {listings.map(l => (
          <ListingCard
            className={css.listingCard}
            key={l.id.uuid}
            listing={l}
            renderSizes={cardRenderSizes}
          />
        ))}
        {props.children}
      </div>
      {paginationLinks}
    </div>
  );
};

FavoriteListResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
};

const { array, node, string } = PropTypes;

FavoriteListResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
};

export default FavoriteListResultsPanel;
