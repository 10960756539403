import Cookies from 'js-cookie';
import config from '../../config';
const useLanguageByLocation = () => {
  //countryCode = undefined for hubista.live
  //countryCode = FI for hubista.fi
  //countryCode = SE for hubista.se

  let countryCode;
  const { swedenDomain, finlandDomain, swedenCountryCode, finlandCountryCode } = config || {};

  if (typeof window !== 'undefined') {
    const currentDomain = window.location.origin;
    if (currentDomain === finlandDomain) {
      countryCode = finlandCountryCode;
    } else if (currentDomain === swedenDomain) {
      countryCode = swedenCountryCode;
    }
  }

  return {
    countryCode: countryCode || Cookies.get('location'),
  };
};
export default useLanguageByLocation;
