import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { sendEmailOnContactUs } from '../../util/api';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SEND_EMAIL_REQUEST = 'app/ContactPage/SEND_EMAIL_REQUEST';
export const SEND_EMAIL_SUCCESS = 'app/ContactPage/SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_ERROR = 'app/ContactPage/SEND_EMAIL_ERROR';

// ================ Reducer ================ //

const initialState = {
  inProgress: false,
  submitError: null,
  submitSucceed: false,
};

export default function profilePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SEND_EMAIL_REQUEST:
      return { ...state, inProgress: true, submitSucceed: false, submitError: null };
    case SEND_EMAIL_SUCCESS:
      return { ...state, inProgress: false, submitSucceed: true, submitError: null };
    case SEND_EMAIL_ERROR:
      return { ...state, inProgress: false, submitSucceed: false, submitError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const sendEmailOnContactPageRequest = userId => ({
  type: SEND_EMAIL_REQUEST,
});

export const sendEmailOnContactPageSuccess = () => ({
  type: SEND_EMAIL_SUCCESS,
});

export const sendEmailOnContactPageError = e => ({
  type: SEND_EMAIL_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const sendEmailOnContactPage = params => (dispatch, getState, sdk) => {
  dispatch(sendEmailOnContactPageRequest());
  return sendEmailOnContactUs(params)
    .then(response => {
      dispatch(sendEmailOnContactPageSuccess());
      return response;
    })
    .catch(e => {
      dispatch(sendEmailOnContactPageError(storableError(e)));
      throw e;
    });
};
