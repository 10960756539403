import React from 'react';
import classNames from 'classnames';
import css from './SectionNewsletter.module.css';
import SubscribeNewsletterForm from '../../forms/SubscribeNewsletterForm/SubscribeNewsletterForm';
import { FormattedMessage } from '../../util/reactIntl';

const SectionNewsletter = props => {
  const {
    className,
    rootClassName,
    onSubscribeEmailToGoogleSheet,
    subscribeInProgress,
    subscribeError,
    subscribeSucceed,
    onResetSucceedState,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <h2 className={css.formTitle}>
        <FormattedMessage id="SectionNewsletter.formTitle" />
      </h2>
      <SubscribeNewsletterForm
        subscribeInProgress={subscribeInProgress}
        onSubmit={values => onSubscribeEmailToGoogleSheet(values.email)}
        subscribeError={subscribeError}
        subscribeSucceed={subscribeSucceed}
        onResetSucceedState={onResetSucceedState}
      />
    </div>
  );
};

export default SectionNewsletter;
