import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  Logo,
  ExternalLink,
  NamedLink,
  IconSocialMediaYoutube,
  SectionNewsletter,
} from '../../components';
import configByLocation from '../../util/hooks/useMarketplaceConfigByLocation';
import css from './Footer.module.css';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle, siteYoutubePage } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });
  const goToYoutube = intl.formatMessage({ id: 'Footer.goToYoutube' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter />
    </ExternalLink>
  ) : null;

  const instagramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;

  const youtubeLink = siteYoutubePage ? (
    <ExternalLink
      key="linkToYoutube"
      href={siteYoutubePage}
      className={css.youtubeIcon}
      title={goToYoutube}
    >
      <IconSocialMediaYoutube />
    </ExternalLink>
  ) : null;
  return [fbLink, twitterLink, instagramLink, youtubeLink].filter(v => v != null);
};

const Footer = props => {
  const {
    rootClassName,
    className,
    intl,
    showSubsribeForm,
    onSubscribeEmailToGoogleSheet,
    subscribeInProgress,
    subscribeError,
    subscribeSucceed,
    onResetSucceedState,
  } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        {showSubsribeForm && (
          <SectionNewsletter
            className={css.newsletterForm}
            onSubscribeEmailToGoogleSheet={onSubscribeEmailToGoogleSheet}
            subscribeError={subscribeError}
            subscribeInProgress={subscribeInProgress}
            subscribeSucceed={subscribeSucceed}
            onResetSucceedState={onResetSucceedState}
          />
        )}
        <div className={css.content}>
          <div className={css.someLiksMobile}>{socialMediaLinks}</div>
          <div className={css.links}>
            <div className={css.organization} id="organization">
              <NamedLink name="LandingPage" className={css.logoLink}>
                <span className={css.logo}>
                  <Logo format="desktop" />
                </span>
              </NamedLink>
              <div className={css.organizationInfo}>
                <p className={css.organizationDescription}>
                  <FormattedMessage id="Footer.organizationDescription" />
                </p>
              </div>
            </div>
            <div className={css.infoLinks}>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink name="SearchPage" className={css.link}>
                    <FormattedMessage id="Footer.toSearchPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="AboutPage" className={css.link}>
                    <FormattedMessage id="Footer.toAboutPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="FAQPage" className={css.link}>
                    <FormattedMessage id="Footer.toFAQPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="ContactPage" className={css.link}>
                    <FormattedMessage id="Footer.toContactPage" />
                  </NamedLink>
                </li>
              </ul>
            </div>
            <div className={css.searches}>
              <ul className={css.list}>
                {configByLocation.maps.search.defaults.slice(0, 4).map((item, index) => (
                  <li className={css.listItem} key={index}>
                    {item.hidden ? (
                      <p className={classNames(css.link, css.lighterLink)}>
                        <span>{item.label}</span>
                      </p>
                    ) : (
                      <NamedLink
                        name="SearchPage"
                        to={{
                          search: `?pub_locations=${item.key}`,
                        }}
                        className={css.link}
                      >
                        <span>{item.label}</span>
                      </NamedLink>
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <div className={css.searchesExtra}>
              <ul className={css.list}>
                {configByLocation.maps.search.defaults.slice(4, 8).map((item, index) => (
                  <li className={css.listItem} key={index}>
                    {item.hidden ? (
                      <p className={classNames(css.link, css.lighterLink)}>
                        <span>{item.label}</span>
                      </p>
                    ) : (
                      <NamedLink
                        name="SearchPage"
                        to={{
                          search: `?pub_locations=${item.key}`,
                        }}
                        className={css.link}
                      >
                        <span>{item.label}</span>
                      </NamedLink>
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <div className={css.extraLinks}>
              <div className={css.someLinks}>{socialMediaLinks}</div>
              <div className={css.legalMatters}>
                <ul className={css.tosAndPrivacy}>
                  <li>
                    <NamedLink name="TermsOfServicePage" className={css.legalLink}>
                      <FormattedMessage id="Footer.termsOfUse" />
                    </NamedLink>
                  </li>
                  <li>
                    <NamedLink name="PrivacyPolicyPage" className={css.legalLink}>
                      <FormattedMessage id="Footer.privacyPolicy" />
                    </NamedLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={css.copyrightAndTermsMobile}>
            <NamedLink name="LandingPage" className={css.organizationCopyrightMobile}>
              <FormattedMessage id="Footer.copyright" />
            </NamedLink>
            <div className={css.tosAndPrivacyMobile}>
              <NamedLink name="PrivacyPolicyPage" className={css.privacy}>
                <FormattedMessage id="Footer.privacy" />
              </NamedLink>
              <NamedLink name="TermsOfServicePage" className={css.terms}>
                <FormattedMessage id="Footer.terms" />
              </NamedLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
