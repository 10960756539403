import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.module.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last Updated: November 1, 2021</p>

      <h2>1. Welcome to Hubista</h2>
      <p>
        Welcome to Hubista and thank you for showing interest in our platform. The following terms
        of service (“Terms”, “terms”) govern the access to and use of the online platform provided
        by Hubista AB (also referred to as Hubista, the platform and/or the Hubista platform) as
        well as services, content, functionality provided by the platform. The platform is run by
        the Swedish limited liability company Hubista AB. You need to accept these terms to be able
        to use this platform
      </p>

      <h2>2. What we do</h2>
      <p>
        Hubista is an online marketplace for services as defined by Hubista. The platform
        facilitates the interaction between a buyer and a seller (jointly referred to as users). A
        seller is a user who promotes its services in a listing on the platform (for the explanation
        of listing see n.n. below). A buyer is a user who purchases such a service via the booking
        function on the platform. Sellers are independent contractors of the buyer and not
        employees, partners, representatives, joint ventures, agents, franchisees or independent
        contractors of Hubista.
      </p>
      <p>
        Hubista is not part of the delivered service itself. Hubista enables the interaction. By
        accepting these terms the users acknowledge that Hubista does not supervise, scope, direct,
        control or monitor a seller's work.Therefore, Hubista disclaims any responsibility and
        liability for the service performed. An agreement between a buyer and a seller is a
        bilateral agreement which does not impose any legal obligations on Hubista.
      </p>

      <h2>3. User</h2>
      <p>
        A user is a physical person or a company (hereafter sometimes referred to as you) who
        creates a profile on the platform. A user profile can be used for two purposes only:
      </p>
      <ul className={css.listText}>
        <li>
          The user profile can be used to sell services through creating a listing (this type of
          user is hereafter referred to as seller)
        </li>
        <li>
          The user profile can be used to browse listings created by other users for the purpose of
          purchasing a service (hereafter referred to as buyer)
        </li>
      </ul>
      <p>
        By creating a profile on this platform the user agrees to follow the terms in this document.
        The user is at all times responsible for keeping up to date with the terms.
      </p>
      <p>
        A userprofile can be used for both of the two purposes above simultaneously as well as one
        of them. Only one profile per user is allowed and the profile is tied to that user. You are
        the sole authorized user of your account and you are solely and fully responsible for all
        content displayed on or via the platform. The user is also responsible for all activities
        that occur under its password and/or account, even if not authorized by the user
      </p>
      <p>
        Hubista will at all time have the right to deactivate and/or remove a user profile and/or
        user content that is not compliant with the terms or with applicable law. Hubista is the
        sole decision maker in such matters. If Hubista decides to undertake such actions a user is
        prohibited from registering a new account in the user’s name, a fictitious- or a borrowed
        name
      </p>
      <p>
        Users may be subject to various controls upon creating a profile which may include, but not
        limited to identity verification and criminal background checks. Even though Hubista may
        perform background checks Hubista cannot confirm that each user is who they claim to be and
        Hubista cannot be held responsible for the accuracy or the reliability of the information
        provided by the user.
      </p>
      <div className={css.child}>
        <h3>
          <span>3.1.</span>Seller
        </h3>
        <p>
          A seller is a physical person or a company who sells its services within any of the given
          service categories on the Hubista platform.
        </p>
        <p>
          As a seller you have to warrant that you are of the legally required age in the
          jurisdiction where you reside and that you have the right, authority, and capacity to
          enter into an agreement. As a seller you must also warrant that you have an unrestricted
          right to work in the jurisdiction in which you will be delivering your services.
        </p>
        <p>
          By accepting these terms a seller grants that it will not use the platform in a way that
          is not compliant with applicable law, the terms and/or in any way that can harm the
          reputation of the platform or Hubista. The seller is responsible to make sure that all
          material published within its profile does not interfere in third party rights or violates
          applicable law.
        </p>
        <p>
          Hubista will at all time have the right to de-activate and/or remove a seller’s profile
          and/or user content that is not compliant with the terms or with applicable law. Hubista
          is the sole decision maker in such matters. If Hubista decides to undertake such actions a
          user is prohibited from registering a new account in the user’s name, a fictitious- or a
          borrowed name.
        </p>
        <p>
          The information filled in by the seller however, is individual and created by the seller.
          The provided information needs to be honest, truthful and compliant with the ethical
          standards of Hubista (for ethical standards see section 19 below).
        </p>
      </div>
      <div className={css.child}>
        <h3>
          <span>3.2.</span>Buyer
        </h3>
        <p>
          A buyer is a physical person or a company who purchases services from a seller via the
          platform. By accepting these terms a buyer grants that it will not use the platform in a
          way that is not compliant with applicable law, the terms and/or in any way that can harm
          the reputation of the platform or Hubista.
        </p>
      </div>
      <h2>4. Service categories</h2>
      <p>
        A user may only use Hubista to promote services in line with the predefined service
        categories as presented on the platform. The range of service categories may vary over time.
        Hubista is the sole decision maker and holds the exclusive right to define the meaning of
        each service category. Once creating a listing, the seller agrees that it is at all times up
        to Hubista to decide if the created listing is in line with the selected service category. A
        seller may not change, modify or interpret the service category in any other way than
        defined by Hubista. Hubista keeps all the rights to review, modify and in the end remove all
        listings that are not in line with the service categories
      </p>
      <p>
        A seller agrees that a service category that the seller sells its services within may be
        withdrawn from the platform at any time. Hubista owns the right to decide if a certain
        category should remain on the platform. If Hubista decides to remove a certain category, the
        listings within this category will not automatically be removed from the platform. However,
        in the case of a removed service category the corresponding listings will lose the
        connection between service category filtering and search functionality. In case of any
        changes to the existing service categories (e.g. removal of a service category), Hubista
        aims to inform all parties via email on best effort basis. If a seller does not find a new
        service category for their listing it might be removed from the platform
      </p>
      <p>
        As stated in section 3, only one profile per user is accepted on the platform. However, a
        seller who wants to carry out sales in multiple service categories can do so by having
        several listings under different service categories (see below).
      </p>
      <h2>5. Listing</h2>
      <p>
        A listing is an advertisement created by the seller with the purpose of selling its
        services. A seller can have several listings under the same profile. This feature is there
        to enable one seller to undertake sales within more than one category and still be compliant
        with section NN above
      </p>
      <p>
        Information provided in the listing must be honest, truthful and compliant with the ethical
        standards of Hubista. When creating a listing the user must ensure that it will not use the
        3 platform for any illegal purposes. The seller is responsible to make sure that all
        material published in the listing does not interfere with the terms, third party rights,
        other users or violate applicable law.
      </p>
      <p>
        Every listing will be reviewed and approved by Hubista. Hubista has the right to request
        changes or additional verifications related to the content in the listing. Hubista, however,
        does not guarantee the content provided by the seller in the listing. Hubista will at all
        time have the right to withdraw a listing and/or remove user content that is not compliant
        with the terms or with applicable law. Hubista is the sole decision maker in such matters
      </p>
      <p>
        When the seller has completed a listing it becomes searchable for other users. A listing is
        tied to a certain service category. This makes the listing searchable for potential buyers.
      </p>
      <div className={css.child}>
        <h3>
          <span>5.1. </span>Availability calendar
        </h3>
        <p>
          To be able to offer a high level of user friendliness the platform has a built in
          availability calendar which is the core of the booking function. Availability calendar is
          tied to a specific listing. In situations where a seller has multiple listings, the seller
          is required to make sure that all availability calendars on listing level reflect their
          actual availability
        </p>
        <p>
          Upon creating a listing the seller has to update its availability calendar to enable the
          booking of time slots. By accepting these terms a seller understands that it is at all
          times a strict requirement to keep the availability calendar/calendars up to date. Upon
          creating a listing the seller fills in the available time slots for a default week. This
          filled in default week is thereafter automatically replicated for the coming 6 months.
          Manual changes can be done at all times in form of exceptions to either add additional
          time slots or to block time slots to make sure that the availability reflects the seller’s
          actual availability. In addition, the seller has the ability to add time in between
          available times slots. This feature is there to enable travel time between bookings.
        </p>
        <p>
          By accepting these terms the seller agrees to deliver the service to the buyer according
          to the agreed schedule.
        </p>
      </div>
      <h2>6. Order</h2>
      <p>
        Hubista offers two booking alternatives (for more information regarding the two alternatives
        see FAQ on the platform). The binding agreement between the two parties arises the moment
        the booking is approved by the seller.
      </p>
      <p>
        Upon ordering, buyers and sellers share information that is needed for the service delivery
        for example service delivery address. The two parties must at all times communicate clearly
        and promptly and the users are at all times responsible to provide honest and truthful
        information upon ordering. The information which the buyer provides constitutes the basis
        for the seller’s approval.
      </p>

      <p>
        Both buyers and sellers are recommended to store information regarding the booking outside
        of the platform for example in a calendar. This is to ensure that buyers and sellers have
        access to such information even in case of a temporary technical disruption on the platform
        by the time of the service delivery. It is at all times the responsibility of the buyer and
        the seller to provide accurate information to enable service delivery. For security reasons
        and to ensure what users have agreed upon all users are advised to let all communication
        between users go via the platform.
      </p>

      <p>
        The seller is required to approve the booking request within six days otherwise the booking
        request will expire. All open booking requests can be found under the user's inbox. When the
        seller has approved the booking, a confirmation is sent to the buyer via email as well as
        through a message function on the platform. If a booking request is not approved upon the
        scheduled starting time of the service the booking will automatically expire
      </p>
      <h2>7. Delivery</h2>
      <div className={css.child}>
        <h3>
          <span>7.1. </span>Seller
        </h3>
        <p>
          Once the booking request is approved, the buyer and the seller have entered a mutual
          agreement and the seller is responsible to deliver the agreed service/services. The seller
          has to deliver the service in a way that is compliant with the terms and the ethical
          standards of Hubista. Hubista is not a part of the delivery itself. Any issues that might
          arise upon service delivery is strictly between the two parties that entered the
          agreement. In case the seller has not delivered the service in a way that is in line with
          the agreement, Hubista has the right to deactivate and/or remove a user profile and/or
          user content for example a listing.
        </p>
        <p>
          By accepting these terms a seller agrees to deliver an agreed upon service even though the
          listing is inactivated, changed or removed from the platform at the time of service
          delivery.
        </p>
        <h3>
          <span>7.2. </span>Buyer
        </h3>
        <p>
          The buyer is responsible to enable the seller to deliver the agreed service/services. As
          an example, if the service delivery takes place at a venue decided by the buyer, the
          latter has to make sure that the venue has the required facilities to enable the seller to
          deliver the service. Facilities refers to the necessities that can be expected from the
          venue where the service should be delivered
        </p>
        <p>
          By accepting these terms a seller agrees to deliver an agreed upon service even though the
          listing is inactivated, changed or removed from the platform at the time of service
          delivery.
        </p>
        <p>
          The information provided by the buyer must be honest and truthful in order to enable a
          smooth service delivery. By accepting these terms, buyers acknowledge that they have no
          right to make any major changes in the information given at the ordering stage without a
          formal permission from the seller. The users are always recommended to communicate via the
          platform.
        </p>
        <p>
          A buyer is moreover always responsible to act in a way which is compliant with the terms
          and with applicable law. In case of any kind of breach, Hubista has the right to
          deactivate and/or remove a user profile and/or user content. Hubista is the sole decision
          maker in such matters
        </p>
      </div>
      <h2>8. Fees</h2>
      <div className={css.child}>
        <h3>
          <span>8.1. </span>Pricing
        </h3>
        <p>
          {' '}
          Sellers are solely responsible for the pricing of their services shown on the listing.
          Hubista does not advise sellers on price related questions. The seller agrees to show its
          total price on the listing. From the amount paid from the buyer to the seller, Hubista
          collects a fee equal to 10 percent from the price set by the seller. No additional costs
          are allowed, at any time, on the listing price as shown on the platform.
        </p>
      </div>
      <h2>9. Payment</h2>
      <p>
        Hubista uses a third party payment service provider (PSP), Stripe. In the event of a buyer’s
        failure to pay for the service, Hubista cannot be obliged to pay the seller.
      </p>
      <p>
        To be able to sell services through the Hubista platform users have to accept the terms of
        Stripe. A seller accepts the terms of Stripe once they register user details on the Stripe
        platform for example payout account details. All payments are processed and collected by
        Stripe. Hubista does not handle the collection of the service price from the buyers or the
        payout to the seller.
      </p>
      <p>
        The pay out will be initiated straight after the end time of the service delivery. Hubista
        cannot guarantee or provide advice regarding the processing times of the payment. All
        questions related to the payment should be addressed to the user’s local bank or Stripe.
      </p>
      <p>
        Users of the platform are neither allowed to offer nor request direct payments outside the
        platform. In case of payment related incidents of this kind, all users are required to
        report them back to Hubista
      </p>
      <h2>10. Reviews</h2>
      <p>
        Hubista applies a review system through which the buyer and the seller are required to
        review one another after the service delivery. This system is in place to enable
        transparency regarding service quality and to support the trustworthiness of a buyer. A
        review will be visible on the platform once both the buyer and the seller have submitted the
        review. The review function is available for seven days after service delivery
      </p>
      <p>
        The review is a combination of star rating and free text. A review is a subjective view from
        a buyer to a seller and vice versa. Hubista does not verify or approve reviews. Therefore,
        Hubista can at no time be held responsible for the content of the review. However, Hubista
        holds the right to edit or withdraw a review or the involved user account/accounts and/or
        listing if the review is not compliant with the terms, third party rights or applicable law.
        Hubista is the sole decision maker in such matters.
      </p>
      <h2>11. Guarantees</h2>
      <p>
        The seller is solely and fully responsible for the truthfulness and accuracy of all content
        within its user profile and/or listing. Hubista cannot be held responsible for the
        information provided by the users of the platform.
      </p>
      <p>
        Hubista does not verify or guarantee the identity of the users of the platform. Hubista does
        not provide guarantees regarding knowledge level or skills of the sellers. In addition,
        Hubista does not guarantee the delivery of the service
      </p>
      <p>
        Furthermore, Hubista is not responsible for the perceived quality of the services provided
        by the sellers. Hubista cannot be held responsible or required to guarantee the quality
      </p>
      <p>
        Users are however encouraged to report back to Hubista if an agreed service deviates from
        the actual delivery or if the service is not delivered at all. The phrase agreed service
        refers to the mutual agreement between the users. This mutual agreement is at all times
        based on the information provided in the listing. Additional information or communication
        provided through the message function on the platform is not part of the mutual agreement.
      </p>
      <h2>12. Refunds and cancellations</h2>
      <p>
        Both buyer and seller have the ability to cancel the booking up to 48 hours before the
        starting time through the platform. A booking that is successfully cancelled will be fully
        refunded to the buyer. The refund will be initiated straight after the cancellation. Hubista
        cannot guarantee or provide advice regarding the processing times of the refund. All
        questions related to the refund should be addressed to the user’s local bank, card provider
        or Stripe
      </p>
      <p>
        In case of a no show by the seller, the buyer is not obliged to pay. In case of a no show by
        the buyer or if the buyer does not enable the seller to deliver the service, the buyer is
        obliged to pay. In such cases the seller will receive its share of the agreed listing price.
        By accepting these terms users agree to do their best to fulfill the agreement towards the
        other party
      </p>
      <p>
        If failure to deliver occurs, for any reason, users are encouraged to report back to
        Hubista. If there should be a dispute regarding such matters Hubista holds the exclusive
        right to decide regarding refunds
      </p>
      <h2>13. Disputes</h2>
      <p>
        Hubista is the provider of the platform and not part of the bilateral agreement between
        buyer and seller. Any potential disputes between the two parties will have to be solved
        between them. By accepting these terms users acknowledge that no involvement can be required
        from Hubista regarding disputes between users.
      </p>
      <h2>14. Changes of the terms</h2>
      <p>
        The terms might change at any point in time and for any given reason. Hubista owns the right
        to undertake any such changes. Such changes become valid 30 days after published or in any
        other way accepted by the user. By accepting these terms the user accepts that such changes
        may occur.
      </p>
      <h2>15. Tax effects</h2>
      <p>
        Selling and purchasing services via the platform may result in tax obligations. The buyer
        and seller are individually responsible for such obligations. For further information
        regarding such matters please be in contact with your local tax authority. Hubista does not
        provide consultation to its users regarding tax related questions.
      </p>
      <h2>16. Links to Third-Party Websites</h2>
      <p>
        The platform enables users to add links to predefined third party websites or resources.
        Such third-party services may be subject to different terms and conditions and/or privacy
        policies. A user of the platform is the sole authorized user of the account and the user is
        solely and fully responsible for all content displayed on or via the platform. By accepting
        these terms a user acknowledges that all content, even accessible via a third party website,
        must be in line with the terms, third-party rights and applicable law. Hubista is the sole
        decision maker in such questions
      </p>
      <p>
        Users are only allowed to link their profile to the predefined websites as defined by
        Hubista. Hubista is not responsible or liable for the availability or accuracy of such
        third-party services or the content, products or services available from such third party
        services. Hubista holds the right to edit or remove user account/accounts and/or information
        provided by users if it is not compliant with the terms, third party rights or applicable
        law
      </p>
      <h2>17. Ethical standard</h2>
      <p>
        Hubista is a platform where interaction between individuals is at the center of its
        existence. In order to buy and sell services through the platform, a mixture of digital and
        physical inaction is required between the users. This combination of digital and physical
        interaction calls for high ethical standards from all users of this platform. Therefore,
        Hubista applies an ethical standard formulated in this document. The ethical standard is
        incorporated in the terms to highlight the importance of these matters. By accepting the
        terms users also accept to follow the ethical standard of Hubista
      </p>
      <ul className={css.listText}>
        <li>
          All users of the platform are required to show respect to each other in the digital and
          the physical interaction
        </li>
        <li>
          Respect has to be shown regardless of gender, sexual identity, sexual orientation,
          ethnicity, religion, political view, social background, function variation and age
        </li>
        <li>
          Users must act with honesty in the digital presentation as well as during the physical
          performance
        </li>
      </ul>
      <p>
        Behaviour, digitally or physically, that is not compliant with the terms can result in
        actions determined in this document. Hubista encourages users to report any behaviour that
        is not in line with the terms of Hubista
      </p>
      <h2>18. Limitation of liability</h2>
      <p>
        Hubista is not liable for technical errors that might occur upon using the platform. Hence,
        any disruptions due to lost network connections or any other disconnection cannot be held
        against Hubista. Hubista does not promise that the service will live up to the users’
        expectations or that the service will be free from disruption or free from deficiencies.
      </p>
      <p>
        Hubista is the provider of the platform and as such we strive to provide a user friendly and
        smooth experience. In case of any damage, direct or indirect or loss in conjunction with the
        use of a service purchased via the platform Hubista will not be liable for compensation or
        in any other way liable. In case of damage, loss and or complaint a buyer should turn to the
        seller. Through an approved booking request a buyer and a seller have entered a mutual
        agreement. It is a bilateral agreement hence any disputes or disagreements between the
        contracting parties will not involve Hubista.
      </p>
      <h2>19. Applicable law and jurisdiction</h2>
      <p>
        These terms will be interpreted in accordance with Swedish law. Disputes arising from the
        terms will be processed in general court with Stockholms Tingsrätt as the first instance
      </p>
      <p>
        If Hubista wishes to enforce any of its rights against you as a consumer, we may do so only
        in the courts of the jurisdiction in which you are a resident. If you are acting as a
        business, you agree to submit to the exclusive jurisdiction of the general court in Sweden
      </p>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
