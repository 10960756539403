import { storableError } from '../util/errors';
import * as log from '../util/log';
import { fetchCurrentUser } from './user.duck';

// ================ Action types ================ //

export const UPDATE_FAVORITE_LIST_REQUEST = 'app/favorite/UPDATE_FAVORITE_LIST_REQUEST';
export const UPDATE_FAVORITE_LIST_SUCCESS = 'app/favorite/UPDATE_FAVORITE_LIST_SUCCESS';
export const UPDATE_FAVORITE_LIST_ERROR = 'app/favorite/UPDATE_FAVORITE_LIST_ERROR';

// ================ Reducer ================ //

const initialState = {
  updateFavoriteListInProgress: null,
  updateFavoriteListError: null,
  isFavoriteListing: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_FAVORITE_LIST_REQUEST:
      return {
        ...state,
        updateFavoriteListInProgress: payload?.listingId,
        updateFavoriteListError: null,
      };
    case UPDATE_FAVORITE_LIST_SUCCESS:
      return {
        ...state,
        updateFavoriteListInProgress: null,
        isFavoriteListing: payload?.newFavoriteState,
      };
    case UPDATE_FAVORITE_LIST_ERROR:
      return {
        ...state,
        updateFavoriteListInProgress: null,
        updateFavoriteListError: payload,
      };
    default:
      return state;
  }
}

// ================ Selectors ================ //

export const updateFavoriteListRequest = listingId => ({
  type: UPDATE_FAVORITE_LIST_REQUEST,
  payload: { listingId },
});

export const updateFavoriteListSuccess = newFavoriteState => ({
  type: UPDATE_FAVORITE_LIST_SUCCESS,
  payload: { newFavoriteState },
});

export const updateFavoriteListError = e => ({
  type: UPDATE_FAVORITE_LIST_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const updateFavoriteList = listingId => async (dispatch, getState, sdk) => {
  try {
    dispatch(updateFavoriteListRequest(listingId));
    await dispatch(fetchCurrentUser());
    const currentUser = getState().user?.currentUser;
    const favoriteList = currentUser?.attributes?.profile?.privateData?.favoriteList || { own: [] };
    const isFavoriteListing = favoriteList?.own.includes(listingId);
    if (isFavoriteListing) {
      favoriteList.own = favoriteList.own.filter(fa => fa !== listingId);
    } else {
      favoriteList?.own.push(listingId);
    }
    await sdk.currentUser.updateProfile({
      privateData: {
        favoriteList,
      },
    });
    dispatch(updateFavoriteListSuccess(!isFavoriteListing));
  } catch (e) {
    log.error(e, 'update-favorite-list-failed');
    dispatch(updateFavoriteListError(storableError(e)));
  }
};

export const updateFavoriteState = newFavoriteState => async (dispatch, getState, sdk) => {
  try {
    dispatch(updateFavoriteListSuccess(newFavoriteState));
  } catch (e) {
    log.error(e, 'update-favorite-state-failed');
  }
};
