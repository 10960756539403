import React, { Component } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import css from './DeliveryMap.module.css';
import config from '../../util/hooks/useMarketplaceConfigByLocation';
import { obfuscatedCoordinates } from '../../util/maps';
import { FormattedMessage } from '../../util/reactIntl';

import { Map, Modal, Button } from '../../components';
class DeliveryMap extends Component {
  constructor(props) {
    super(props);
    this.state = { isStatic: true, isModalOpen: false };
    this.openMapOnGoogleApp = this.openMapOnGoogleApp.bind(this);
  }

  openMapOnGoogleApp = () => {
    const { address, isAccepted, directlyAddress } = this.props;
    this.setState({ isModalOpen: false });

    if (isAccepted) {
      return window.open(`http://maps.google.com?q=${directlyAddress}`);
    }

    return window.open(`http://maps.google.com?q=${address}`);
  };

  render() {
    const {
      className,
      rootClassName,
      geolocation,
      publicData,
      listingId,
      zoom,
      offset,
      bounds,
    } = this.props;
    if (!geolocation) {
      return null;
    }

    const address = publicData && publicData.location ? publicData.location.address : '';
    const classes = classNames(rootClassName || css.sectionMap, className);
    const cacheKey = listingId ? `${listingId.uuid}_${geolocation.lat}_${geolocation.lng}` : null;

    const mapProps = config.maps.fuzzy.enabled
      ? { obfuscatedCenter: obfuscatedCoordinates(geolocation, cacheKey) }
      : { address, center: geolocation };
    const map = (
      <Map
        bounds={bounds}
        zoom={zoom}
        offset={offset}
        {...mapProps}
        useStaticMap={this.state.isStatic}
      />
    );

    return (
      <div className={classes}>
        {this.state.isStatic ? (
          <button
            className={css.map}
            onClick={() => {
              this.setState({ isModalOpen: true });
            }}
          >
            {map}
          </button>
        ) : (
          <div className={css.map}>{map}</div>
        )}
        <Modal
          id="DeliveryMap.googleMapConfirmModal"
          isOpen={this.state.isModalOpen}
          onClose={() => this.setState({ isModalOpen: false })}
          onManageDisableScrolling={() => {}}
          scrollLayerClassName={css.confirmModal}
          containerClassName={css.containerModal}
        >
          <div className={css.modalContent}>
            <p className={css.openIn}>
              <FormattedMessage id="DeliveryMap.openInGoogleMap" />
            </p>
            <div className={css.confirmActions}>
              <Button
                onClick={() => this.setState({ isModalOpen: false, isStatic: false })}
                type="button"
              >
                <FormattedMessage id="DeliveryMap.no" />
              </Button>
              <Button onClick={this.openMapOnGoogleApp} type="button">
                <FormattedMessage id="DeliveryMap.yes" />
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

DeliveryMap.defaultProps = {
  rootClassName: null,
  className: null,
  geolocation: null,
  listingId: null,
};

DeliveryMap.propTypes = {
  rootClassName: string,
  className: string,
  geolocation: propTypes.latlng,
  listingId: propTypes.uuid,
};

export default DeliveryMap;
