// Add here the translations of the country names using key "<language_code>: 'transalation'" e.g. fi: 'Afganistan'
// prettier-ignore
const countryCodes = [
  { code: 'AF', en: 'Afghanistan', fi: 'Afghanistan', se: 'Afganistán', de: 'Afghanistan' },
  { code: 'AX', en: 'Åland Islands', fi: 'Ahvenanmaa', se: 'Åland', de: 'Åland' },
  { code: 'AL', en: 'Albania', fi: 'Albania', se: 'Albanien', de: 'Albanien' },
  { code: 'DZ', en: 'Algeria', fi: 'Algérie', se: 'Algeriet', de: 'Algerien' },
  { code: 'AS', en: 'American Samoa', fi: 'Amerikan Samoa', se: 'Amerikanska Samoa', de: 'Amerikanisch-Samoa' },
  { code: 'AD', en: 'Andorra', fi: 'Andorra', se: 'Andorra', de: 'Andorra' },
  { code: 'AO', en: 'Angola', fi: 'Angola', se: 'Angola', de: 'Angola' },
  { code: 'AI', en: 'Anguilla', fi: 'Anguilla', se: 'Anguila', de: 'Anguilla' },
  { code: 'AQ', en: 'Antarctica', fi: 'Antarctique', se: 'Antarktis', de: 'Antarktika' },
  { code: 'AG', en: 'Antigua and Barbuda', fi: 'Antigua ja Barbuda', se: 'Antigua och Barbuda', de: 'Antigua und Barbuda' },
  { code: 'AR', en: 'Argentina', fi: 'Argentiina', se: 'Argentina', de: 'Argentinien' },
  { code: 'AM', en: 'Armenia', fi: 'Armenia', se: 'Armenien', de: 'Armenien' },
  { code: 'AW', en: 'Aruba', fi: 'Aruba', se: 'Aruba', de: 'Aruba' },
  { code: 'AU', en: 'Australia', fi: 'Australia', se: 'Australien', de: 'Australien' },
  { code: 'AT', en: 'Austria', fi: 'Itävalta', se: 'Österrike', de: 'Österreich' },
  { code: 'AZ', en: 'Azerbaijan', fi: 'Azerbaidžan', se: 'Azerbajdzjan', de: 'Aserbaidschan' },
  { code: 'BS', en: 'Bahamas', fi: 'Bahama', se: 'Bahamas', de: 'Bahamas' },
  { code: 'BH', en: 'Bahrain', fi: 'Bahreïn', se: 'Bahréin', de: 'Bahrain' },
  { code: 'BD', en: 'Bangladesh', fi: 'Bangladesh', se: 'Bangladesh', de: 'Bangladesch' },
  { code: 'BB', en: 'Barbados', fi: 'Barbade', se: 'Barbados', de: 'Barbados' },
  { code: 'BY', en: 'Belarus', fi: 'Belarus', se: 'Belarus', de: 'Belarus' },
  { code: 'BE', en: 'Belgium', fi: 'Belgia', se: 'Belgien', de: 'Belgien' },
  { code: 'BZ', en: 'Belize', fi: 'Belize', se: 'Belice', de: 'Belize' },
  { code: 'BJ', en: 'Benin', fi: 'Benin', se: 'Benin', de: 'Benin' },
  { code: 'BM', en: 'Bermuda', fi: 'Bermudes', se: 'Bermudas', de: 'Bermuda' },
  { code: 'BT', en: 'Bhutan', fi: 'Bhoutan', se: 'Bhután', de: 'Bhutan' },
  { code: 'BO', en: 'Bolivia', fi: 'Bolivia', se: 'Bolivia', de: 'Bolivien' },
  { code: 'BQ', en: 'Bonaire, Sint Eustatius and Saba', fi: 'Alankomaiden Karibia', se: 'Karibiska Nederländerna', de: 'Bonaire, Sint Eustatius und Saba' },
  { code: 'BA', en: 'Bosnia and Herzegovina', fi: 'Bosnia ja Hertsegovina', se: 'Bosnien och Hercegovina', de: 'Bosnien und Herzegowina' },
  { code: 'BW', en: 'Botswana', fi: 'Botswana', se: 'Botswana', de: 'Botswana' },
  { code: 'BV', en: 'Bouvet Island', fi: 'Bouvetin saari', se: 'Bouvetön', de: 'Bouvetinsel' },
  { code: 'BR', en: 'Brazil', fi: 'Brasilia', se: 'Brasilien', de: 'Brasilien' },
  { code: 'IO', en: 'British Indian Ocean Territory', fi: 'Brittiläinen Intian valtameren alue', se: 'Brittiska territoriet i Indiska oceanen', de: 'Britisches Territorium im Indischen Ozean' },
  { code: 'BN', en: 'Brunei Darussalam', fi: 'Brunei Darussalam', se: 'Brunei Darussalam', de: 'Brunei Darussalam' },
  { code: 'BG', en: 'Bulgaria', fi: 'Bulgaria', se: 'Bulgarien', de: 'Bulgarien' },
  { code: 'BF', en: 'Burkina Faso', fi: 'Burkina Faso', se: 'Burkina Faso', de: 'Burkina Faso' },
  { code: 'BI', en: 'Burundi', fi: 'Burundi', se: 'Burundi', de: 'Burundi' },
  { code: 'KH', en: 'Cambodia', fi: 'Kambodža', se: 'Kambodja', de: 'Kambodscha' },
  { code: 'CM', en: 'Cameroon', fi: 'Kamerun', se: 'Kamerun', de: 'Kamerun' },
  { code: 'CA', en: 'Canada', fi: 'Kanada', se: 'Kanada', de: 'Kanada' },
  { code: 'CV', en: 'Cape Verde', fi: 'Kap Verde', se: 'Cape Verde', de: 'Kap Verde' },
  { code: 'KY', en: 'Cayman Islands', fi: 'Caymansaaret', se: 'Caymanöarna', de: 'Kaimaninseln' },
  { code: 'CF', en: 'Central African Republic', fi: 'Keski-Afrikan tasavalta', se: 'Centralafrikanska republiken', de: 'Zentralafrikanische Republik' },
  { code: 'TD', en: 'Chad', fi: 'Tšad', se: 'Tchad', de: 'Tschad' },
  { code: 'CL', en: 'Chile', fi: 'Chile', se: 'Chile', de: 'Chile' },
  { code: 'CN', en: 'China', fi: 'Kiina', se: 'Kina', de: 'China, Volksrepublik' },
  { code: 'CX', en: 'Christmas Island', fi: 'Joulusaari', se: 'Julön', de: 'Weihnachtsinsel' },
  { code: 'CC', en: 'Cocos (Keeling) Islands', fi: 'Kookossaaret', se: 'Kokosöarna', de: 'Kokosinseln' },
  { code: 'CO', en: 'Colombia', fi: 'Kolumbia', se: 'Colombia', de: 'Kolumbien' },
  { code: 'KM', en: 'Comoros', fi: 'Komorit', se: 'Komorerna', de: 'Komoren' },
  { code: 'CG', en: 'Congo', fi: 'Kongo', se: 'Kongo', de: 'Kongo, Republik' },
  { code: 'CD', en: 'Congo, the Democratic Republic of the', fi: 'Kongo, demokraattinen tasavalta', se: 'Kongo, Demokratiska republiken', de: 'Kongo, Demokratische Republik' },
  { code: 'CK', en: 'Cook Islands', fi: 'Cook saaret', se: 'Cooköarna', de: 'Cookinseln' },
  { code: 'CR', en: 'Costa Rica', fi: 'Costa Rica', se: 'Costa Rica', de: 'Costa Rica' },
  { code: 'CI', en: 'Côte d\'Ivoire', fi: 'Norsunluurannikko', se: 'Elfenbenskusten', de: "Côte d'Ivoire" },
  { code: 'HR', en: 'Croatia', fi: 'Kroatia', se: 'Kroatien', de: 'Kroatien' },
  { code: 'CU', en: 'Cuba', fi: 'Kuuba', se: 'Kuba', de: 'Kuba' },
  { code: 'CW', en: 'Curaçao', fi: 'Curaçao', se: 'Curazao', de: 'Curaçao' },
  { code: 'CY', en: 'Cyprus', fi: 'Kypros', se: 'Cypern', de: 'Zypern' },
  { code: 'CZ', en: 'Czech Republic', fi: 'Tšekin tasavalta', se: 'Tjeckien', de: 'Tschechien' },
  { code: 'DK', en: 'Denmark', fi: 'Tanska', se: 'Danmark', de: 'Dänemark' },
  { code: 'DJ', en: 'Djibouti', fi: 'Djibouti', se: 'Djibouti', de: 'Dschibuti' },
  { code: 'DM', en: 'Dominica', fi: 'Dominique', se: 'Domínica', de: 'Dominica' },
  { code: 'DO', en: 'Dominican Republic', fi: 'Dominikaaninen tasavalta', se: 'Dominikanska republiken', de: 'Dominikanische Republik' },
  { code: 'EC', en: 'Ecuador', fi: 'Ecuador', se: 'Ecuador', de: 'Ecuador' },
  { code: 'EG', en: 'Egypt', fi: 'Egypti', se: 'Egypten', de: 'Ägypten' },
  { code: 'SV', en: 'El Salvador', fi: 'Salvador', se: 'El Salvador', de: 'El Salvador' },
  { code: 'GQ', en: 'Equatorial Guinea', fi: 'Päiväntasaajan Guinea', se: 'Ekvatorialguinea', de: 'Äquatorialguinea' },
  { code: 'ER', en: 'Eritrea', fi: 'Érythrée', se: 'Eritrea', de: 'Eritrea' },
  { code: 'EE', en: 'Estonia', fi: 'Viro', se: 'Estland', de: 'Estland' },
  { code: 'ET', en: 'Ethiopia', fi: 'Etiopia', se: 'Etiopien', de: 'Äthiopien' },
  { code: 'FK', en: 'Falkland Islands (Malvinas)', fi: 'Falklandinsaaret (Malviinit)', se: 'Falklandsöarna (Malvinas)', de: 'Falklandinseln' },
  { code: 'FO', en: 'Faroe Islands', fi: 'Färsaaret', se: 'Färöarna', de: 'Färöer' },
  { code: 'FJ', en: 'Fiji', fi: 'Fidži', se: 'Fiji', de: 'Fidschi' },
  { code: 'FI', en: 'Finland', fi: 'Suomi', se: 'Finland', de: 'Finnland' },
  { code: 'FR', en: 'France', fi: 'Ranska', se: 'Frankrike', de: 'Frankreich' },
  { code: 'GF', en: 'French Guiana', fi: 'Ranskan Guayana', se: 'Franska Guyana', de: 'Französisch-Guayana' },
  { code: 'PF', en: 'French Polynesia', fi: 'Ranskan Polynesia', se: 'Franska Polynesien', de: 'Französisch-Polynesien' },
  { code: 'TF', en: 'French Southern Territories', fi: 'Ranskan eteläiset alueet', se: 'Franska Syd Territorier', de: 'Französische Süd- und Antarktisgebiete' },
  { code: 'GA', en: 'Gabon', fi: 'Gabon', se: 'Gabon', de: 'Gabun' },
  { code: 'GM', en: 'Gambia', fi: 'Gambie', se: 'Gambia', de: 'Gambia' },
  { code: 'GE', en: 'Georgia', fi: 'Georgia', se: 'Georgien', de: 'Georgien' },
  { code: 'DE', en: 'Germany', fi: 'Saksa', se: 'Tyskland', de: 'Deutschland' },
  { code: 'GH', en: 'Ghana', fi: 'Ghana', se: 'Ghana', de: 'Ghana' },
  { code: 'GI', en: 'Gibraltar', fi: 'Gibraltar', se: 'Gibraltar', de: 'Gibraltar' },
  { code: 'GR', en: 'Greece', fi: 'Kreikka', se: 'Grekland', de: 'Griechenland' },
  { code: 'GL', en: 'Greenland', fi: 'Grönlanti', se: 'Grönland', de: 'Grönland' },
  { code: 'GD', en: 'Grenada', fi: 'Grenade', se: 'Granada', de: 'Grenada' },
  { code: 'GP', en: 'Guadeloupe', fi: 'Guadeloupe', se: 'Guadalupe', de: 'Guadeloupe' },
  { code: 'GU', en: 'Guam', fi: 'Guam', se: 'Guam', de: 'Guam' },
  { code: 'GT', en: 'Guatemala', fi: 'Guatemala', se: 'Guatemala', de: 'Guatemala' },
  { code: 'GG', en: 'Guernsey', fi: 'Guernesey', se: 'Guernsey', de: 'Guernsey' },
  { code: 'GN', en: 'Guinea', fi: 'Guinée', se: 'Guinea', de: 'Guinea' },
  { code: 'GW', en: 'Guinea-Bissau', fi: 'Guinea-Bissau', se: 'Guinea-Bissau', de: 'Guinea-Bissau' },
  { code: 'GY', en: 'Guyana', fi: 'Guyane', se: 'Guayana', de: 'Guyana' },
  { code: 'HT', en: 'Haiti', fi: 'Haiti', se: 'Haiti', de: 'Haiti' },
  { code: 'HM', en: 'Heard Island and McDonald Islands', fi: 'Heard Island ja McDonald Islands', se: 'Heard Island och McDonald Islands', de: 'Heard und McDonaldinseln' },
  { code: 'VA', en: 'Holy See (Vatican City State)', fi: 'Pyhä istuin (Vatikaanivaltio)', se: 'Heliga stolen (Vatikanstaten)', de: 'Vatikanstadt' },
  { code: 'HN', en: 'Honduras', fi: 'Honduras', se: 'Honduras', de: 'Honduras' },
  { code: 'HK', en: 'Hong Kong', fi: 'Hong Kong', se: 'Hong Kong', de: 'Hongkong' },
  { code: 'HU', en: 'Hungary', fi: 'Unkari', se: 'Ungern', de: 'Ungarn' },
  { code: 'IS', en: 'Iceland', fi: 'Islanti', se: 'Island', de: 'Island' },
  { code: 'IN', en: 'India', fi: 'Intia', se: 'Indien', de: 'Indien' },
  { code: 'ID', en: 'Indonesia', fi: 'Indonesia', se: 'Indonesien', de: 'Indonesien' },
  { code: 'IR', en: 'Iran, Islamic Republic of', fi: 'Iran', se: 'Iran', de: 'Iran, Islamische Republik' },
  { code: 'IQ', en: 'Iraq', fi: 'Irak', se: 'Irak', de: 'Irak' },
  { code: 'IE', en: 'Ireland', fi: 'Irlanti', se: 'Irland', de: 'Irland' },
  { code: 'IM', en: 'Isle of Man', fi: 'Mansaari', se: 'Mannin', de: 'Insel Man' },
  { code: 'IL', en: 'Israel', fi: 'Israel', se: 'Israel', de: 'Israel' },
  { code: 'IT', en: 'Italy', fi: 'Italia', se: 'Italien', de: 'Italien' },
  { code: 'JM', en: 'Jamaica', fi: 'Jamaika', se: 'Jamaica', de: 'Jamaika' },
  { code: 'JP', en: 'Japan', fi: 'Japani', se: 'Japan', de: 'Japan' },
  { code: 'JE', en: 'Jersey', fi: 'Jersey', se: 'Jersey', de: 'Jersey (Kanalinsel)' },
  { code: 'JO', en: 'Jordan', fi: 'Jordania', se: 'Jordanien', de: 'Jordanien' },
  { code: 'KZ', en: 'Kazakhstan', fi: 'Kazakstan', se: 'Kazakstan', de: 'Kasachstan' },
  { code: 'KE', en: 'Kenya', fi: 'Kenia', se: 'Kenia', de: 'Kenia' },
  { code: 'KI', en: 'Kiribati', fi: 'Kiribati', se: 'Kiribati', de: 'Kiribati' },
  { code: 'KP', en: 'North Korea', fi: 'Pohjois-Korea', se: 'Nordkorea', de: 'Korea, Demokratische Volksrepublik (Nordkorea)' },
  { code: 'KR', en: 'South Korea', fi: 'Etelä-Korea', se: 'Sydkorea', de: 'Korea, Republik (Südkorea)' },
  { code: 'KW', en: 'Kuwait', fi: 'Kowait', se: 'Kuwait', de: 'Kuwait' },
  { code: 'KG', en: 'Kyrgyzstan', fi: 'Kirgisia', se: 'Kirgizistan', de: 'Kirgisistan' },
  { code: 'LA', en: 'Laos', fi: 'Laos', se: 'Laos', de: 'Laos' },
  { code: 'LV', en: 'Latvia', fi: 'Latvia', se: 'Lettland', de: 'Lettland' },
  { code: 'LB', en: 'Lebanon', fi: 'Libanon', se: 'Libanon', de: 'Libanon' },
  { code: 'LS', en: 'Lesotho', fi: 'Lesotho', se: 'Lesotho', de: 'Lesotho' },
  { code: 'LR', en: 'Liberia', fi: 'Liberia', se: 'Liberia', de: 'Liberia' },
  { code: 'LY', en: 'Libya', fi: 'Libya', se: 'Libyen', de: 'Libyen' },
  { code: 'LI', en: 'Liechtenstein', fi: 'Liechtenstein', se: 'Liechtenstein', de: 'Liechtenstein' },
  { code: 'LT', en: 'Lithuania', fi: 'Liettua', se: 'Litauen', de: 'Litauen' },
  { code: 'LU', en: 'Luxembourg', fi: 'Luxemburg', se: 'Luxemburg', de: 'Luxemburg' },
  { code: 'MO', en: 'Macao', fi: 'Macao', se: 'Macao', de: 'Macau' },
  { code: 'MK', en: 'North Macedonia', fi: 'Pohjois-Makedonia', se: 'Nordmakedonien', de: 'Nordmazedonien' },
  { code: 'MG', en: 'Madagascar', fi: 'Madagaskar', se: 'Madagaskar', de: 'Madagaskar' },
  { code: 'MW', en: 'Malawi', fi: 'Malawi', se: 'Malawi', de: 'Malawi' },
  { code: 'MY', en: 'Malaysia', fi: 'Malesia', se: 'Malasia', de: 'Malaysia' },
  { code: 'MV', en: 'Maldives', fi: 'Malediivit', se: 'Maldiverna', de: 'Malediven' },
  { code: 'ML', en: 'Mali', fi: 'Mali', se: 'Mali', de: 'Mali' },
  { code: 'MT', en: 'Malta', fi: 'Malta', se: 'Malta', de: 'Malta' },
  { code: 'MH', en: 'Marshall Islands', fi: 'Marshallsaaret', se: 'Marshallöarna', de: 'Marshallinseln' },
  { code: 'MQ', en: 'Martinique', fi: 'Martinique', se: 'Martinique', de: 'Martinique' },
  { code: 'MR', en: 'Mauritania', fi: 'Mauritania', se: 'Mauretanien', de: 'Mauretanien' },
  { code: 'MU', en: 'Mauritius', fi: 'Mauritius', se: 'Mauritius', de: 'Mauritius' },
  { code: 'YT', en: 'Mayotte', fi: 'Mayotte', se: 'Mayotte', de: 'Mayotte' },
  { code: 'MX', en: 'Mexico', fi: 'Meksiko', se: 'Mexiko', de: 'Mexiko' },
  { code: 'FM', en: 'Micronesia, Federated States of', fi: 'Mikronesia', se: 'Mikronesien', de: 'Mikronesien' },
  { code: 'MD', en: 'Moldova', fi: 'Moldova', se: 'Moldavien', de: 'Moldawien' },
  { code: 'MC', en: 'Monaco', fi: 'Monaco', se: 'Monaco', de: 'Monaco' },
  { code: 'MN', en: 'Mongolia', fi: 'Mongolia', se: 'Mongoliet', de: 'Mongolei' },
  { code: 'ME', en: 'Montenegro', fi: 'Montenegro', se: 'Montenegro', de: 'Montenegro' },
  { code: 'MS', en: 'Montserrat', fi: 'Montserrat', se: 'Montserrat', de: 'Montserrat' },
  { code: 'MA', en: 'Morocco', fi: 'Marokko', se: 'Marocko', de: 'Marokko' },
  { code: 'MZ', en: 'Mozambique', fi: 'Mosambik', se: 'Moçambique', de: 'Mosambik' },
  { code: 'MM', en: 'Myanmar', fi: 'Myanmar', se: 'Myanmar', de: 'Myanmar' },
  { code: 'NA', en: 'Namibia', fi: 'Namibia', se: 'Namibia', de: 'Namibia' },
  { code: 'NR', en: 'Nauru', fi: 'Nauru', se: 'Nauru', de: 'Nauru' },
  { code: 'NP', en: 'Nepal', fi: 'Népal', se: 'Nepal', de: 'Nepal' },
  { code: 'NL', en: 'Netherlands', fi: 'Alankomaat', se: 'Nederländerna', de: 'Niederlande' },
  { code: 'NC', en: 'New Caledonia', fi: 'Uusi-Caledonia', se: 'Nyland-Kaledonien', de: 'Neukaledonien' },
  { code: 'NZ', en: 'New Zealand', fi: 'Uusi Seelanti', se: 'Nya Zeeland', de: 'Neuseeland' },
  { code: 'NI', en: 'Nicaragua', fi: 'Nicaragua', se: 'Nicaragua', de: 'Nicaragua' },
  { code: 'NE', en: 'Niger', fi: 'Niger', se: 'Níger', de: 'Niger' },
  { code: 'NG', en: 'Nigeria', fi: 'Nigeria', se: 'Nigeria', de: 'Nigeria' },
  { code: 'NU', en: 'Niue', fi: 'Niue', se: 'Niue', de: 'Niue' },
  { code: 'NF', en: 'Norfolk Island', fi: 'Norfolkin saari', se: 'Norfolkön', de: 'Norfolkinsel' },
  { code: 'MP', en: 'Northern Mariana Islands', fi: 'Pohjois-Mariaanit', se: 'Nordmarianerna', de: 'Nördliche Marianen' },
  { code: 'NO', en: 'Norway', fi: 'Norja', se: 'Norge', de: 'Norwegen' },
  { code: 'OM', en: 'Oman', fi: 'Oman', se: 'Oman', de: 'Oman' },
  { code: 'PK', en: 'Pakistan', fi: 'Pakistan', se: 'Pakistan', de: 'Pakistan' },
  { code: 'PW', en: 'Palau', fi: 'Palau', se: 'Palau', de: 'Palau' },
  { code: 'PS', en: 'Palestine, State of', fi: 'Palestiina', se: 'Palestina', de: 'Staat Palästina' },
  { code: 'PA', en: 'Panama', fi: 'Panama', se: 'Panama', de: 'Panama' },
  { code: 'PG', en: 'Papua New Guinea', fi: 'Papua-Uusi-Guinea', se: 'Papua Nya Guinea', de: 'Papua-Neuguinea' },
  { code: 'PY', en: 'Paraguay', fi: 'Paraguay', se: 'Paraguay', de: 'Paraguay' },
  { code: 'PE', en: 'Peru', fi: 'Peru', se: 'Peru', de: 'Peru' },
  { code: 'PH', en: 'Philippines', fi: 'Filippiinit', se: 'Filippinerna', de: 'Philippinen' },
  { code: 'PN', en: 'Pitcairn', fi: 'Pitcairn', se: 'Islas Pitcairn', de: 'Pitcairninseln' },
  { code: 'PL', en: 'Poland', fi: 'Puola', se: 'Polen', de: 'Polen' },
  { code: 'PT', en: 'Portugal', fi: 'Portugali', se: 'Portugal', de: 'Portugal' },
  { code: 'PR', en: 'Puerto Rico', fi: 'Puerto Rico', se: 'Puerto Rico', de: 'Puerto Rico' },
  { code: 'QA', en: 'Qatar', fi: 'Qatar', se: 'Qatar', de: 'Katar' },
  { code: 'RE', en: 'Réunion', fi: 'Réunion', se: 'Reunión', de: 'Réunion' },
  { code: 'RO', en: 'Romania', fi: 'Romania', se: 'Rumänien', de: 'Rumänien' },
  { code: 'RU', en: 'Russia', fi: 'Venäjä', se: 'Ryssland', de: 'Russische Föderation' },
  { code: 'RW', en: 'Rwanda', fi: 'Ruanda', se: 'Ruanda', de: 'Ruanda' },
  { code: 'BL', en: 'Saint Barthélemy', fi: 'Saint Barthélemy', se: 'Saint Barthélemy', de: 'Saint-Barthélemy' },
  { code: 'SH', en: 'Saint Helena, Ascension and Tristan da Cunha', fi: 'Saint Helena, Ascension ja Tristan da Cunha', se: 'Sankta Helena, Ascension och Tristan da Cunha', de: 'St. Helena' },
  { code: 'KN', en: 'Saint Kitts and Nevis', fi: 'Saint Kitts ja Nevis', se: 'Saint Kitts och Nevis', de: 'St. Kitts und Nevis' },
  { code: 'LC', en: 'Saint Lucia', fi: 'Saint Lucia', se: 'Saint Lucia', de: 'St. Lucia' },
  { code: 'MF', en: 'Saint Martin (French part)', fi: 'Saint Martin (ranskalainen osa)', se: 'Saint Martin (franska delen)', de: 'Saint-Martin (franz. Teil)' },
  { code: 'PM', en: 'Saint Pierre and Miquelon', fi: 'Saint Pierre ja Miquelon', se: 'Saint Pierre och Miquelon', de: 'Saint-Pierre und Miquelon' },
  { code: 'VC', en: 'Saint Vincent and the Grenadines', fi: 'Saint Vincent ja Grenadiinit', se: 'Saint Vincent och Grenadinerna', de: 'St. Vincent und die Grenadinen' },
  { code: 'WS', en: 'Samoa', fi: 'Samoa', se: 'Samoa', de: 'Samoa' },
  { code: 'SM', en: 'San Marino', fi: 'San Marino', se: 'San Marino', de: 'San Marino' },
  { code: 'ST', en: 'Sao Tome and Principe', fi: 'Sao Tome ja Principe', se: 'Sao Tomé och Principe', de: 'São Tomé und Príncipe' },
  { code: 'SA', en: 'Saudi Arabia', fi: 'Saudi-Arabia', se: 'Saudiarabien', de: 'Saudi-Arabien' },
  { code: 'SN', en: 'Senegal', fi: 'Senegal', se: 'Senegal', de: 'Senegal' },
  { code: 'RS', en: 'Serbia', fi: 'Serbia', se: 'Serbien', de: 'Serbien' },
  { code: 'SC', en: 'Seychelles', fi: 'Seychellit', se: 'Seychellerna', de: 'Seychellen' },
  { code: 'SL', en: 'Sierra Leone', fi: 'Sierra Leone', se: 'Sierra Leona', de: 'Sierra Leone' },
  { code: 'SG', en: 'Singapore', fi: 'Singapore', se: 'Singapore', de: 'Singapur' },
  { code: 'SX', en: 'Sint Maarten (Dutch part)', fi: 'Sint Maarten (Hollannin osa)', se: 'Sint Maarten (nederländska delen)', de: 'Sint Maarten (niederl. Teil)' },
  { code: 'SK', en: 'Slovakia', fi: 'Slovakia', se: 'Slovakien', de: 'Slowakei' },
  { code: 'SI', en: 'Slovenia', fi: 'Slovenia', se: 'Slovenien', de: 'Slowenien' },
  { code: 'SB', en: 'Solomon Islands', fi: 'Solomonsaaret', se: 'Salomonöarna', de: 'Salomonen' },
  { code: 'SO', en: 'Somalia', fi: 'Somalia', se: 'Somalia', de: 'Somalia' },
  { code: 'ZA', en: 'South Africa', fi: 'Etelä-Afrikka', se: 'Sydafrika', de: 'Südafrika' },
  { code: 'GS', en: 'South Georgia and the South Sandwich Islands', fi: 'Etelä-Georgia ja Eteläiset Sandwichsaaret', se: 'South Georgia och South Sandwich Islands', de: 'Südgeorgien und die Südlichen Sandwichinseln' },
  { code: 'SS', en: 'South Sudan', fi: 'Etelä-Sudan', se: 'södra Sudan', de: 'Südsudan' },
  { code: 'ES', en: 'Spain', fi: 'Espanja', se: 'Spanien', de: 'Spanien' },
  { code: 'LK', en: 'Sri Lanka', fi: 'Sri Lanka', se: 'Sri Lanka', de: 'Sri Lanka' },
  { code: 'SD', en: 'Sudan', fi: 'Soudan', se: 'Sudán', de: 'Sudan' },
  { code: 'SR', en: 'Suriname', fi: 'Suriname', se: 'Surinam', de: 'Suriname' },
  { code: 'SJ', en: 'Svalbard and Jan Mayen', fi: 'Svalbard ja Jan Mayen', se: 'Svalbard och Jan Mayen', de: 'Svalbard und Jan Mayen' },
  { code: 'SZ', en: 'Swaziland', fi: 'Swazimaa', se: 'Swaziland', de: 'Swasiland' },
  { code: 'SE', en: 'Sweden', fi: 'Ruotsi', se: 'Sverige', de: 'Schweden' },
  { code: 'CH', en: 'Switzerland', fi: 'Sveitsi', se: 'Schweiz', de: 'Schweiz' },
  { code: 'SY', en: 'Syrian Arab Republic', fi: 'Syyria', se: 'Syrien Arabrepubliken', de: 'Syrien, Arabische Republik' },
  { code: 'TW', en: 'Taiwan', fi: 'Taiwan', se: 'Taiwan', de: 'Taiwan' },
  { code: 'TJ', en: 'Tajikistan', fi: 'Tadžikistan', se: 'Tadzjikistan', de: 'Tadschikistan' },
  { code: 'TZ', en: 'Tanzania', fi: 'Tansania', se: 'Tanzania', de: 'Tansania' },
  { code: 'TH', en: 'Thailand', fi: 'Thaimaa', se: 'Thailand', de: 'Thailand' },
  { code: 'TL', en: 'Timor-Leste', fi: 'Itä-Timor', se: 'Östtimor', de: 'Osttimor ' },
  { code: 'TG', en: 'Togo', fi: 'Togo', se: 'Togo', de: 'Togo' },
  { code: 'TK', en: 'Tokelau', fi: 'Tokelau', se: 'Tokelau', de: 'Tokelau' },
  { code: 'TO', en: 'Tonga', fi: 'Tonga', se: 'Tonga', de: 'Tonga' },
  { code: 'TT', en: 'Trinidad and Tobago', fi: 'Trinidad ja Tobago', se: 'Trinidad och Tobago', de: 'Trinidad und Tobago' },
  { code: 'TN', en: 'Tunisia', fi: 'Tunisia', se: 'Tunisien', de: 'Tunesien' },
  { code: 'TR', en: 'Turkey', fi: 'Turkki', se: 'Turkiet', de: 'Türkei' },
  { code: 'TM', en: 'Turkmenistan', fi: 'Turkmenistan', se: 'Turkmenistan', de: 'Turkmenistan' },
  { code: 'TC', en: 'Turks and Caicos Islands', fi: 'Turks-ja Caicossaaret', se: 'Turks- och Caicosöarna', de: 'Turks- und Caicosinseln' },
  { code: 'TV', en: 'Tuvalu', fi: 'Tuvalu', se: 'Tuvalu', de: 'Tuvalu' },
  { code: 'UG', en: 'Uganda', fi: 'Uganda', se: 'Uganda', de: 'Uganda' },
  { code: 'UA', en: 'Ukraine', fi: 'Ukraina', se: 'Ukraina', de: 'Ukraine' },
  { code: 'AE', en: 'United Arab Emirates', fi: 'Yhdistyneet Arabiemiirikunnat', se: 'Förenade arabemiraten', de: 'Vereinigte Arabische Emirate' },
  { code: 'GB', en: 'United Kingdom', fi: 'Iso-Britannia', se: 'Storbritannien', de: 'Vereinigtes Königreich Großbritannien und Nordirland' },
  { code: 'US', en: 'United States', fi: 'Yhdysvallat', se: 'Förenta staterna', de: 'Vereinigte Staaten von Amerika' },
  { code: 'UM', en: 'United States Minor Outlying Islands', fi: 'Yhdysvaltain pienet syrjäiset saaret', se: 'Förenta staternas mindre avlägsna öar', de: 'United States Minor Outlying Islands' },
  { code: 'UY', en: 'Uruguay', fi: 'Uruguay', se: 'Uruguay', de: 'Uruguay' },
  { code: 'UZ', en: 'Uzbekistan', fi: 'Uzbekistan', se: 'Uzbekistan', de: 'Usbekistan' },
  { code: 'VU', en: 'Vanuatu', fi: 'Vanuatu', se: 'Vanuatu', de: 'Vanuatu' },
  { code: 'VE', en: 'Venezuela', fi: 'Venezuela', se: 'Venezuela', de: 'Venezuela' },
  { code: 'VN', en: 'Viet Nam', fi: 'Vietnam', se: 'Vietnam', de: 'Vietnam' },
  { code: 'VG', en: 'Virgin Islands, British', fi: 'Neitsytsaaret, Iso-Britannia', se: 'Jungfruöarna, brittiska', de: 'Britische Jungferninseln' },
  { code: 'VI', en: 'Virgin Islands, U.S.', fi: 'Neitsytsaaret, Yhdysvallat', se: 'Jungfruöarna, USA', de: 'Amerikanische Jungferninseln' },
  { code: 'WF', en: 'Wallis and Futuna', fi: 'Wallis ja Futuna', se: 'Wallis och Futuna', de: 'Wallis und Futuna' },
  { code: 'EH', en: 'Western Sahara', fi: 'Länsi-Sahara', se: 'Västra Sahara', de: 'Westsahara' },
  { code: 'YE', en: 'Yemen', fi: 'Jemen', se: 'Jemen', de: 'Jemen' },
  { code: 'ZM', en: 'Zambia', fi: 'Sambia', se: 'Zambia', de: 'Sambia' },
  { code: 'ZW', en: 'Zimbabwe', fi: 'Zimbabwe', se: 'Zimbabue', de: 'Simbabwe' },
];

const getCountryCodes = lang => {
  // Add the lnew locale here so that the correct translations will be returned.
  // If locale is unknown or the translation is missing, this will default to english coutnry name.
  const codes = countryCodes.map(c => {
    const countryName = c[lang] ? c[lang] : c['en'];
    const counryCode = c.code;

    return { code: counryCode, name: countryName };
  });
  return codes;
};

export default getCountryCodes;
