import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../util/hooks/useMarketplaceConfigByLocation';
import { Form, Button, FieldTextInput, FieldSelect } from '../../components';

import css from './EditListingDescriptionForm.module.css';

const TITLE_MAX_LENGTH = 60;

const EditListingDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        textNextButton,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        isNewListingFlow,
      } = formRenderProps;

      const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titlePlaceholder',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);

      const descriptionMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.description',
      });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionPlaceholder',
      });
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionRequired',
      });

      // Language skills
      const languageSkillsMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.languageSkills',
      });
      const languageSkillsPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.languageSkillsPlaceholder',
      });
      const languageSkillsRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.languageSkillsRequired',
      });

      const serviceCategoryLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.serviceCategoryLabel',
      });
      const serviceCategoryPlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.serviceCategoryPlaceholder',
      });
      const serviceCategoryRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.serviceCategoryRequired',
      });

      const educationLabel = intl.formatMessage({
        id: 'EditListingDescriptionForm.educationLabel',
      });
      const educationPlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.educationPlaceholder',
      });
      const educationRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.educationRequiredMessage',
      });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const options = findOptionsForSelectFilter('serviceCategory', filterConfig);
      const activeOptions = options.filter(op => !op.hidden);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="text"
            label={titleMessage}
            placeholder={titlePlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
            autoFocus
          />

          <FieldTextInput
            id="description"
            name="description"
            className={css.description}
            type="textarea"
            label={descriptionMessage}
            placeholder={descriptionPlaceholderMessage}
            validate={composeValidators(required(descriptionRequiredMessage))}
          />

          <FieldSelect
            id="serviceCategory"
            className={css.serviceCategory}
            label={serviceCategoryLabel}
            name="serviceCategory"
            validate={required(serviceCategoryRequiredMessage)}
          >
            <option value="" disabled>
              {serviceCategoryPlaceholder}
            </option>
            {activeOptions.map(option => (
              <option value={option.key} key={option.key}>
                {intl.formatMessage({ id: `ServiceCategory.label.${option.key}` })}
              </option>
            ))}
          </FieldSelect>

          <FieldTextInput
            id="educationBackground"
            name="educationBackground"
            className={css.education}
            type="textarea"
            label={educationLabel}
            placeholder={educationPlaceholder}
            validate={composeValidators(required(educationRequiredMessage))}
          />

          <FieldTextInput
            id="languageSkills"
            name="languageSkills"
            className={css.languageSkills}
            type="textarea"
            label={languageSkillsMessage}
            placeholder={languageSkillsPlaceholderMessage}
            validate={composeValidators(required(languageSkillsRequiredMessage))}
          />

          <div className={classNames({ [css.wrapperButton]: isNewListingFlow })}>
            <Button
              className={classNames(css.submitButton, { [css.buttonActive]: !submitDisabled })}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {textNextButton}
            </Button>
          </div>
        </Form>
      );
    }}
  />
);

EditListingDescriptionFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  textNextButton: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
