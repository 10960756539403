import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { required } from '../../util/validators';
import { Form, Button, FieldSelect } from '../../components';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';

import css from './EditListingLocationForm.module.css';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../util/hooks/useMarketplaceConfigByLocation';

export const EditListingLocationFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        updated,
        updateInProgress,
        fetchErrors,
        textNextButton,
        textPreviousButton,
        isNewListingFlow,
        onGoBack,
        filterConfig,
      } = formRenderProps;

      const titleRequiredMessage = intl.formatMessage({ id: 'EditListingLocationForm.address' });
      const addressPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressPlaceholder',
      });
      const addressRequiredMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressRequired',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const handleAddMoreService = fields => () => {
        fields.push('');
      };

      const handleRemoveMoreService = (fields, index) => () => {
        fields.remove(index);
      };

      const options = findOptionsForSelectFilter('location', filterConfig);

      const filterLocation = (locationOptions, fields, index) => {
        return locationOptions.filter(item => {
          return (
            !item.hidden && (fields.value[index] === item.key || !fields.value.includes(item.key))
          );
        });
      };

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <FieldArray name="locations">
            {({ fields }) => (
              <div>
                {fields.map((name, index) => (
                  <div key={name} className={classNames(css.field, css.locationItem)}>
                    <FieldSelect
                      id="location"
                      className={css.selectedCategory}
                      label={titleRequiredMessage}
                      name={`${name}`}
                      validate={required(addressRequiredMessage)}
                    >
                      <option value="" disabled>
                        {addressPlaceholderMessage}
                      </option>
                      {filterLocation(options, fields, index).map(option => (
                        <option value={option.key} key={option.key}>
                          {option.label}
                        </option>
                      ))}
                    </FieldSelect>
                    <Button
                      className={css.addMoreLocation}
                      onClick={handleAddMoreService(fields)}
                      type="button"
                    >
                      +
                    </Button>
                    {index !== 0 && (
                      <Button
                        className={css.removeLocation}
                        onClick={handleRemoveMoreService(fields, index)}
                        type="button"
                      >
                        -
                      </Button>
                    )}
                  </div>
                ))}
              </div>
            )}
          </FieldArray>

          <div className={classNames({ [css.wrapperButton]: isNewListingFlow })}>
            {isNewListingFlow && (
              <Button
                className={css.submitButton}
                type="button"
                ready={submitReady}
                onClick={onGoBack}
              >
                {textPreviousButton}
              </Button>
            )}
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {textNextButton}
            </Button>
          </div>
        </Form>
      );
    }}
  />
);

EditListingLocationFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingLocationFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  textNextButton: string.isRequired,
  textPreviousButton: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

export default compose(injectIntl)(EditListingLocationFormComponent);
