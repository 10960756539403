import useLanguageByLocation from '../../../util/hooks/useLanguageByLocation';
import config from '../../../config';

const defaultLocationsLandingPage = [
  {
    key: 'stockholm',
    label: 'Stockholm',
  },
  {
    key: 'gothenburg',
    label: 'Göteborg',
  },
  {
    key: 'malmo',
    label: 'Malmö',
  },
];

const defaultLocationsLandingPageInFinland = [
  {
    key: 'helsinki',
    label: 'Helsinki',
  },
  {
    key: 'espoo',
    label: 'Espoo',
  },
  {
    key: 'tampere',
    label: 'Tampere',
  },
  {
    key: 'oulu',
    label: 'Oulu',
  },
];

const currentCountryCode = useLanguageByLocation().countryCode;

const exportLocations =
  currentCountryCode === config.swedenCountryCode
    ? defaultLocationsLandingPage
    : currentCountryCode === config.finlandCountryCode
    ? defaultLocationsLandingPageInFinland
    : defaultLocationsLandingPage;

export default exportLocations;
