import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import useMarketplaceConfigByLocation from '../../util/hooks/useMarketplaceConfigByLocation';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney, unitDivisor } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { Button, Form, FieldTextInput, FieldCurrencyInput, FieldSelect } from '../../components';
import css from './EditListingPricingForm.module.css';
import { currencyConfiguration } from '../../currency-config';
const { Money } = sdkTypes;

const euroCurrency = process.env.REACT_APP_SHARETRIBE_MARKETPLACE_CURRENCY_FINLAND;
const sekCurrency = process.env.REACT_APP_SHARETRIBE_MARKETPLACE_CURRENCY_SWEDEN;

export const EditListingPricingFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        updated,
        updateInProgress,
        fetchErrors,
        textNextButton,
        textPreviousButton,
        isNewListingFlow,
        onGoBack,
        values,
        form,
        inLiveSite,
      } = formRenderProps;

      const unitType = useMarketplaceConfigByLocation.bookingUnitType;
      const isNightly = unitType === LINE_ITEM_NIGHT;
      const isDaily = unitType === LINE_ITEM_DAY;

      const serviceNameLabel = intl.formatMessage({
        id: 'EditListingPricingForm.serviceNameLabel',
      });
      const sessionLengthLabel = intl.formatMessage({
        id: 'EditListingPricingForm.sessionLengthLabel',
      });

      const translationKey = isNightly
        ? 'EditListingPricingForm.pricePerNight'
        : isDaily
        ? 'EditListingPricingForm.pricePerDay'
        : 'EditListingPricingForm.pricePerUnit';

      const pricePerUnitMessage = currencyCode =>
        intl.formatMessage(
          {
            id: translationKey,
          },
          {
            currencyCode,
          }
        );

      const pricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.priceInputPlaceholder',
      });

      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingPricingForm.priceRequired',
        })
      );
      const marketplaceCurrency = useMarketplaceConfigByLocation.currency;
      const {
        listingMinimumPriceSubUnits,
        listingMaximumPriceSubUnits,
      } = useMarketplaceConfigByLocation;
      const minPrice = new Money(listingMinimumPriceSubUnits, marketplaceCurrency);
      const maxPrice = new Money(
        listingMaximumPriceSubUnits[marketplaceCurrency],
        marketplaceCurrency
      );
      const rangePriceRequired = validators.moneySubUnitAmountInRange(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceInRange',
          },
          {
            minPrice: formatMoney(intl, minPrice),
            maxPrice: formatMoney(intl, maxPrice),
          }
        ),
        listingMinimumPriceSubUnits,
        listingMaximumPriceSubUnits[marketplaceCurrency]
      );
      const priceValidators =
        listingMinimumPriceSubUnits >= 0 && listingMaximumPriceSubUnits[marketplaceCurrency]
          ? validators.composeValidators(priceRequired, rangePriceRequired)
          : priceRequired;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { updateListingError, showListingsError } = fetchErrors || {};

      const currencyLabel = intl.formatMessage({
        id: 'EditListingPricingForm.currencyLabel',
      });

      const currencyPlaceholder = intl.formatMessage({
        id: 'EditListingPricingForm.currencyPlaceholder',
      });

      const onChangeCurrency = (e, form) => {
        const currentServicePricing = [...form.getState().values.price].map(p => ({
          ...p,
          price: null,
        }));
        form.change('currency', e);
        form.change('price', currentServicePricing);
      };

      const currencyFieldMaybe = inLiveSite && (
        <FieldSelect
          className={css.currencyField}
          label={currencyLabel}
          name="currency"
          id="currency"
          onChange={e => onChangeCurrency(e, form)}
        >
          <option disabled value="">
            {currencyPlaceholder}
          </option>
          <option value={euroCurrency}>{euroCurrency}</option>
          <option value={sekCurrency}>{sekCurrency}</option>
        </FieldSelect>
      );

      const currentCurrency = values.currency
        ? currencyConfiguration(values.currency)
        : useMarketplaceConfigByLocation.currencyConfig;

      return (
        <Form onSubmit={handleSubmit} className={classes}>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.updateFailed" />
            </p>
          ) : null}
          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.showListingFailed" />
            </p>
          ) : null}
          {currencyFieldMaybe}
          <FieldArray name="price">
            {({ fields }) =>
              fields.map((name, index) => (
                <div className={css.priceContainer} key={index}>
                  <FieldTextInput
                    id={`${name}.service`}
                    name={`${name}.service`}
                    className={css.field}
                    label={`${serviceNameLabel} ${index + 1}`}
                    disabled={true}
                  />

                  <FieldTextInput
                    id={`${name}.sessionLengthText`}
                    name={`${name}.sessionLengthText`}
                    className={css.field}
                    label={sessionLengthLabel}
                    disabled={true}
                  />

                  <FieldCurrencyInput
                    id={`${name}.price`}
                    name={`${name}.price`}
                    className={classNames(css.field, css.priceInput)}
                    label={pricePerUnitMessage(currentCurrency.currency)}
                    placeholder={pricePlaceholderMessage}
                    currencyConfig={currentCurrency}
                    validate={priceValidators}
                  />
                </div>
              ))
            }
          </FieldArray>

          <div className={classNames({ [css.wrapperButton]: isNewListingFlow })}>
            {isNewListingFlow && (
              <Button
                className={css.submitButton}
                type="button"
                ready={submitReady}
                onClick={onGoBack}
              >
                {textPreviousButton}
              </Button>
            )}
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {textNextButton}
            </Button>
          </div>
        </Form>
      );
    }}
  />
);

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  textNextButton: string.isRequired,
  textPreviousButton: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
