import React, { Component } from 'react';
import { arrayOf, func, node, number, object, shape, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { Menu, MenuContent, MenuItem, MenuLabel, IconCheckbox, FilterButton } from '..';
import css from './SelectSingleFilterPopup.module.css';
import { FILTER_BOUNDS_PARAM } from '../../util/types';

const optionLabel = (options, key) => {
  const option = options.find(o => o.key === key);
  return option ? option.label : key;
};

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

class SelectSingleFilterPopup extends Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false };
    this.onToggleActive = this.onToggleActive.bind(this);
    this.selectOption = this.selectOption.bind(this);
  }

  onToggleActive(isOpen) {
    this.setState({ isOpen: isOpen });
  }

  selectOption(queryParamName, option) {
    this.setState({ isOpen: false });

    if (queryParamName === FILTER_BOUNDS_PARAM) {
      //handle clear option for bounds
      const { key, address } = option ? option : { key: null, address: null };
      this.props.onSelect({ bounds: key, address: address });
    } else {
      this.props.onSelect({ [queryParamName]: option ? option.key : null });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isOpen } = this.state;

    if (prevState.isOpen !== isOpen) {
      this.props.setIsDarkerLayerOpen(isOpen);
    }
  }

  render() {
    const {
      rootClassName,
      className,
      label,
      selectionLabel,
      options,
      queryParamNames,
      initialValues,
      contentPlacementOffset,
    } = this.props;

    const queryParamName = getQueryParamName(queryParamNames);
    const initialValue =
      initialValues && initialValues[queryParamNames] ? initialValues[queryParamNames] : null;

    // resolve menu label text and class
    const menuLabel = initialValue ? optionLabel(options, initialValue) : label;
    const menuLabelClass = initialValue ? css.menuLabelSelected : css.menuLabel;

    const classes = classNames(rootClassName || css.root, className);

    return (
      <Menu
        className={classes}
        useArrow={false}
        contentPlacementOffset={contentPlacementOffset}
        onToggleActive={this.onToggleActive}
        isOpen={this.state.isOpen}
      >
        <MenuLabel className={menuLabelClass}>
          <span className={css.menuLabelText}>{menuLabel}</span>
        </MenuLabel>
        <MenuContent className={css.menuContent}>
          <MenuItem key="SectionLabel">
            <span className={css.selectionLabel}>{selectionLabel}</span>
          </MenuItem>
          {options
            .filter(o => !o.hidden)
            .map(option => {
              // check if this option is selected
              const selected = initialValue === option.key;

              return (
                <MenuItem key={option.key}>
                  <button
                    className={css.menuItem}
                    onClick={() => this.selectOption(queryParamName, option)}
                  >
                    <span className={css.checkboxWrapper}>
                      <IconCheckbox isChecked={selected} boxClassName={css.box} />
                    </span>
                    {option.label}
                  </button>
                </MenuItem>
              );
            })}
          <MenuItem key={'clearLink'}>
            <button
              className={css.clearMenuItem}
              onClick={() => this.selectOption(queryParamName, null)}
            >
              <FormattedMessage id={'SelectSingleFilter.popupClear'} />
            </button>
          </MenuItem>
        </MenuContent>
      </Menu>
    );
  }
}

SelectSingleFilterPopup.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  contentPlacementOffset: 0,
};

SelectSingleFilterPopup.propTypes = {
  rootClassName: string,
  className: string,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,
  onSelect: func.isRequired,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  initialValues: object,
  contentPlacementOffset: number,
};

export default SelectSingleFilterPopup;
