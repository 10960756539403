import React from 'react';
import { FieldSelect } from '..';
import css from './ListingServiceDropdown.module.css';

const ListingServiceDropdown = props => {
  const { id, name, label, services = [], placeholderOption, onChange } = props;
  return (
    <FieldSelect
      rootClassName={css.rootContent}
      id={id}
      name={name}
      label={label}
      onChange={onChange}
    >
      <option value="" disabled>
        {placeholderOption}
      </option>
      {services.map((service, index) => (
        <option key={index} value={service.serviceId}>
          {service.service}
        </option>
      ))}
    </FieldSelect>
  );
};

export default ListingServiceDropdown;
